<div class="container bg-light position-relative app-participant-detail">
    <div class="row bg-white p-0">
        <!-- Col Left -->
        <div class="col-lg-8 bg-white pl-0 pr-0" [ngClass]="parentClass" style="position: relative">
            <!-- <div class="app-share-button app-cursor" (click)="shbClick($event);" [class.app-share-button--active]="shareBtnAct">
                 <svg class="icon fa-share-alt"><use href="#icon-share"/></svg>
                <div class="app-share-button__links app-share-button__links--alternate">
                     <app-shared-buttons [max]="sharedMax"></app-shared-buttons>
                </div>
            </div> -->
            <ng-container *ngIf="data as participant; else elseBlock">
                <div *ngIf="loadedError" class="pb-6">
                    <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                        <div class="text-center pt-7">
                            <svg class="icon icon--64 mb-4"><use [attr.xlink:href]="'#icon-' + sportName" /></svg>
                            <h4 class="font-weight-bold">{{ 'web.we_are_sorry' | translate }}</h4>
                            <p class="mb-6">{{ 'web.error_data_load' | translate }}</p>
                        </div>
                    </div>
                </div>

                <div class="pl-5 pt-5 pr-5 pr-md-3 pb-3 app-participant-detail__info">
                    <div class="row">
                        <div class="col-md-10">
                            <div class="media">
                                <div class="img-circle img-circle--100 mr-3 rounded-circle">
                                    <img
                                        [src]="cdn + participant.image | responsive : 'participant' : 'large'"
                                        [attr.alt]="participant.name"
                                        class="app-participant-logo"
                                        height="100"
                                        width="100"
                                    />
                                </div>
                                <div class="media-body">
                                    <div class="d-flex icon-wrappe r justify-content-start flex-wrap">
                                        <h1 class="mb-2 mr-3" data-cy="participant-name">
                                            {{ participant.name }}
                                            <app-favorite [participantId]="data.id" [type]="'participant'">
                                                <span notactive>{{
                                                    'web.add_participant_to_favorites' | translate
                                                }}</span>
                                                <span active>{{
                                                    'web.remove_participant_from_favorites' | translate
                                                }}</span>
                                            </app-favorite>
                                            <div
                                                *ngIf="hasWinLoseStats"
                                                class="app-fighter-bilance d-flex flex-wrap flex-md-nowrap"
                                            >
                                                <div
                                                    class="app-fighter-bilance__item app-fighter-bilance--win d-flex flex-nowrap justify-content-between"
                                                >
                                                    <div>{{ 'web.participant_record_win' | translate }}</div>
                                                    <div>{{ participantRecord[0] }}</div>
                                                </div>
                                                <div
                                                    class="app-fighter-bilance__item app-fighter-bilance--lose d-flex flex-nowrap justify-content-between"
                                                >
                                                    <div>{{ 'web.participant_record_lose' | translate }}</div>
                                                    <div>{{ participantRecord[1] }}</div>
                                                </div>
                                                <div
                                                    class="app-fighter-bilance__item app-fighter-bilance--draw d-flex flex-nowrap justify-content-between"
                                                >
                                                    <div>{{ 'web.participant_record_draw' | translate }}</div>
                                                    <div>{{ participantRecord[2] }}</div>
                                                </div>
                                            </div>
                                        </h1>
                                        <img
                                            *ngIf="participant.country_flag"
                                            class="mr-3 mb-2"
                                            [src]="cdn + participant.country_flag"
                                            [attr.alt]=""
                                            height="35"
                                        />
                                        <div class="break-md"></div>
                                        <div class="mb-3 mb-lg-0">
                                            <ng-container
                                                ><app-last-matches
                                                    [limit]="6"
                                                    [matches]="formReverse"
                                                ></app-last-matches
                                            ></ng-container>
                                        </div>
                                    </div>
                                    <div
                                        class="mb-2 h5 d-flex"
                                        *ngIf="participant.tournament && participant.tournament.length > 0"
                                    >
                                        <span *ngIf="!isTennis" class="font-weight-bold mr-1">
                                            <a
                                                [routerLink]="[
                                                    '/page/sport/event',
                                                    sportName + '-' + sportId,
                                                    participant.tournament[0]?.tournament_id
                                                ]"
                                                class="text-reset"
                                            >
                                                {{ participant.tournament[0].sub_tournament_name }}</a
                                            >
                                        </span>
                                        <span>({{ participant.tournament[0].category_name }})</span>
                                        <!-- <div class="img-circle img-circle--20 ml-2 rounded-circle">
                                <img src="img/flags/4x3/es.svg" alt="Flag of Spain" height="20">
                              </div> -->
                                    </div>
                                    <div>
                                        <a
                                            *ngFor="let t of uniqueTournaments; trackBy: trackByIndex"
                                            class="crumb-inline crumb-inline__link"
                                            [routerLink]="['/page/sport/event/', sportName + '-' + sportId, t.id]"
                                            >{{ t.name }}</a
                                        >
                                    </div>

                                    <div class="ng-star-inserted" *ngIf="participant.venue">
                                        <span class="ng-star-inserted" *ngIf="participant.venue?.stadium">
                                            <span class="text-gray">{{ 'web.home_stadium' | translate }}:</span>
                                            {{ participant.venue?.stadium }}
                                            <ng-container *ngIf="participant.venue.place">
                                                ({{ participant.venue.place }})
                                            </ng-container>
                                            <ng-container *ngIf="client.use('map') && participant.venue.stadium_coords">
                                                -
                                                <a class="link ext-link app-cursor" (click)="map = !map"
                                                    >{{ 'web.map' | translate }} &nbsp;</a
                                                >
                                            </ng-container>
                                        </span>
                                        <span class="ng-star-inserted" *ngIf="participant.venue?.stadium_capacity">
                                            <span class="text-gray"
                                                >{{ 'web.home_stadium_capacity' | translate }}:</span
                                            >
                                            {{ participant.venue?.stadium_capacity | appnumber }}
                                        </span>
                                        <span class="ng-star-inserted" *ngIf="participant.state">
                                            <br />
                                            <span class="text-gray">{{ 'web.state' | translate }}:</span>
                                            {{ 'web.state_' + participant.state | lowercase | translate }}
                                        </span>
                                    </div>

                                    <div class="text-gray">
                                        <table>
                                            <tr *ngIf="rankingStats">
                                                <td class="pr-lg-4">
                                                    <div class="d-block d-md-inline app-word-nowrap">
                                                        {{ rankingStats.translation | translate }}
                                                        <span class="font-weight-bold text-body">
                                                            <br />
                                                            #{{ rankingStats.value }}
                                                        </span>
                                                        <span class="px-2"></span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr *ngIf="participant.tennis_rankings_single != 0">
                                                <td class="pr-lg-4">
                                                    <div class="d-block d-md-inline app-word-nowrap">
                                                        <ng-container *ngIf="participant.tennis_rankings_single != null"
                                                            >{{ 'web.tennis_single_rainking' | translate }}
                                                            <span class="font-weight-bold text-body">
                                                                <br />
                                                                #{{ participant.tennis_rankings_single }}
                                                            </span>
                                                            <ng-container
                                                                *ngIf="+participant.tennis_movement_single != 0"
                                                            >
                                                                <svg
                                                                    *ngIf="+participant.tennis_movement_single > 0"
                                                                    class="icon icon--8 icon--success ml-2"
                                                                >
                                                                    <use xlink:href="#icon-arrow-up"></use>
                                                                </svg>
                                                                <svg
                                                                    *ngIf="+participant.tennis_movement_single < 0"
                                                                    class="icon icon--8 icon--danger ml-2"
                                                                >
                                                                    <use xlink:href="#icon-arrow-down"></use>
                                                                </svg>
                                                                &nbsp;<small
                                                                    [class.text-success]="
                                                                        +participant.tennis_movement_single > 0
                                                                    "
                                                                    [class.text-danger]="
                                                                        +participant.tennis_movement_single < 0
                                                                    "
                                                                    >{{ participant.tennis_movement_single }}</small
                                                                >
                                                            </ng-container>
                                                            <span class="px-2"></span>
                                                        </ng-container>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="d-block d-md-inline app-word-nowrap">
                                                        <ng-container *ngIf="participant.tennis_points_single != null">
                                                            {{ 'web.tennis_single_points' | translate }}
                                                            <span class="font-weight-bold text-body">
                                                                <br />
                                                                {{ participant.tennis_points_single }}</span
                                                            >
                                                            <span class="px-2"></span
                                                        ></ng-container>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr *ngIf="participant.tennis_rankings_double != 0">
                                                <td>
                                                    <div class="d-block d-md-inline app-word-nowrap">
                                                        <ng-container *ngIf="participant.tennis_rankings_double != null"
                                                            >{{ 'web.tennis_double_rainking' | translate }}
                                                            <span class="font-weight-bold text-body">
                                                                <br />#{{ participant.tennis_rankings_double }}</span
                                                            >
                                                            <ng-container
                                                                *ngIf="+participant.tennis_movement_double != 0"
                                                            >
                                                                <svg
                                                                    *ngIf="+participant.tennis_movement_double > 0"
                                                                    class="icon icon--8 icon--success ml-2"
                                                                >
                                                                    <use xlink:href="#icon-arrow-up"></use>
                                                                </svg>
                                                                <svg
                                                                    *ngIf="+participant.tennis_movement_double < 0"
                                                                    class="icon icon--8 icon--danger ml-2"
                                                                >
                                                                    <use xlink:href="#icon-arrow-down"></use>
                                                                </svg>
                                                                &nbsp;<small
                                                                    [class.text-success]="
                                                                        +participant.tennis_movement_double > 0
                                                                    "
                                                                    [class.text-danger]="
                                                                        +participant.tennis_movement_double < 0
                                                                    "
                                                                    >{{ participant.tennis_movement_double }}</small
                                                                >
                                                            </ng-container>
                                                            <span class="px-2"></span>
                                                        </ng-container>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="d-block d-md-inline app-word-nowrap">
                                                        <ng-container *ngIf="participant.tennis_points_double != null">
                                                            {{ 'web.tennis_double_points' | translate }}
                                                            <span class="font-weight-bold text-body">
                                                                <br />{{ participant.tennis_points_double }}</span
                                                            >
                                                            <span class="px-2"></span
                                                        ></ng-container>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr
                                                *ngIf="
                                                    participant.birthplace &&
                                                    participant.birthplace.country &&
                                                    participant.birthplace.city
                                                "
                                            >
                                                <td>
                                                    <div class="d-block d-md-inline app-word-nowrap">
                                                        {{ 'web.birthplace_country' | translate }}
                                                        <br />
                                                        <span class="font-weight-bold text-body">
                                                            {{ participant.birthplace.country }}</span
                                                        >
                                                        <span class="px-2"></span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="d-block d-md-inline app-word-nowrap">
                                                        {{ 'web.birthplace_city' | translate }}
                                                        <br />
                                                        <span class="font-weight-bold text-body">
                                                            {{ participant.birthplace.city }}</span
                                                        >
                                                        <span class="px-2"></span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <ng-container
                                                *ngIf="participant.meta?.main && participant.meta?.main?.length > 0"
                                            >
                                                <ng-container *ngFor="let pData of metaMain; trackBy: trackByIndex">
                                                    <tr>
                                                        <ng-container *ngFor="let row of pData; trackBy: trackByIndex">
                                                            <td valign="top" class="pr-lg-4">
                                                                <span class="app-word-nowrap text-gray">
                                                                    {{ row.translation | translate }}:
                                                                </span>
                                                                <br />
                                                                <div
                                                                    class="d-block d-md-inline font-weight-bold text-body"
                                                                >
                                                                    <ng-container [ngSwitch]="row.name">
                                                                        <ng-container *ngSwitchCase="'birthdate'">
                                                                            {{ row.value | livDate : 'birthdate' }} ({{
                                                                                row.value | livDate : 'age'
                                                                            }}
                                                                            {{
                                                                                'web.years-old' | translate | transinit
                                                                            }})
                                                                        </ng-container>
                                                                        <ng-container *ngSwitchCase="'dominant-hand'">
                                                                            {{ row.value | translate }}
                                                                        </ng-container>

                                                                        <ng-container *ngSwitchDefault>
                                                                            {{ row.value }}
                                                                        </ng-container>
                                                                    </ng-container>

                                                                    <!-- <ng-container *ngIf="row.type === 'birthdate'">
                                                            {{ row.value | livDate: 'birthdate' }} ({{
                                                                row.value | livDate: 'age'
                                                            }}
                                                            {{ 'web.years-old' | translate | transinit }})
                                                        </ng-container>

                                                        <ng-container *ngIf="row.type !== 'birthdate'">{{
                                                            row.value
                                                        }}</ng-container> -->
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                    </tr>
                                                </ng-container>
                                            </ng-container>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="col-md-2 text-md-right mt-2 mt-md-auto shared-btn-parent"
                            *ngIf="client.use('share')"
                        >
                            <div class="d-flex flex-row-reverse flex-md-column align-items-end">
                                <div class="dropdown ml-3 mb-md-2 dropdown-share">
                                    <button
                                        class="btn btn-light dropdown-share-btn"
                                        (click)="shbClick($event)"
                                        [class.app-share-button--active]="shareBtnAct"
                                        type="button"
                                        id="dropdown-share-btn"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <svg class="icon"><use xlink:href="#icon-share" /></svg>
                                        <div class="app-share-button__links app-share-button__links--alternate">
                                            <app-shared-buttons [max]="sharedMax"></app-shared-buttons>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="app-share-button app-cursor" (click)="shbClick($event);" [class.app-share-button--active]="shareBtnAct">
                         <svg class="icon fa-share-alt"><use href="#icon-share"/></svg>
                        <div class="app-share-button__links app-share-button__links--alternate">
                             <app-shared-buttons [max]="sharedMax"></app-shared-buttons>
                        </div>
                    </div> -->
                    </div>
                </div>

                <div class="nav-wrapper app-participant-detail__nav" style="position: relative">
                    <!-- <div class="app-mob-nav-shadow"></div> -->
                    <ul class="nav nav-tabs nav-tabs--shadows font-weight-bold pt-3" id="tabs-left" role="tablist">
                        <li class="nav-item">
                            <a
                                class="nav-link active px-3 px-md-5"
                                [class.active]="activeTab == 1"
                                id="tab1"
                                data-toggle="tab"
                                href="#tab1-content"
                                (click)="activeTabFce(1)"
                                [routerLink]="['./']"
                                fragment="overview"
                                role="tab"
                                aria-controls="tab1-content"
                                aria-selected="true"
                                >{{ 'web.overview' | translate | transinit }}</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link px-3 px-md-5"
                                data-cy="finished"
                                [class.active]="activeTab == 2"
                                id="tab2"
                                data-toggle="tab"
                                href="#tab2-content"
                                [routerLink]="['./']"
                                fragment="results"
                                role="tab"
                                (click)="activeTabFce(2)"
                                aria-controls="tab2-content"
                                aria-selected="false"
                                >{{ 'web.finished_matches' | renamesportnav : sportName | translate | transinit }}</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link px-3 px-md-5"
                                data-cy="future_matches"
                                [class.active]="activeTab == 3"
                                id="tab3"
                                data-toggle="tab"
                                href="#tab3-content"
                                [routerLink]="['./']"
                                fragment="future"
                                role="tab"
                                (click)="activeTabFce(3)"
                                aria-controls="tab3-content"
                                aria-selected="false"
                                >{{ 'web.future_matches' | renamesportnav : sportName | translate | transinit }}</a
                            >
                        </li>
                        <li class="nav-item" *ngIf="participant.players.length > 0">
                            <a
                                class="nav-link px-3 px-md-5"
                                data-cy="players"
                                [class.active]="activeTab == 4"
                                id="tab4"
                                data-toggle="tab"
                                href="#tab4-content"
                                [routerLink]="['./']"
                                fragment="players"
                                role="tab"
                                (click)="activeTabFce(4)"
                                aria-controls="tab4-content"
                                aria-selected="false"
                                >{{ 'web.players' | translate | transinit }}</a
                            >
                        </li>
                        <li class="nav-item" *ngIf="participant?.meta?.basic && participant.meta!.basic!.length > 0">
                            <a
                                class="nav-link px-3 px-md-5"
                                data-cy="basic"
                                [class.active]="activeTab == 5"
                                id="tab5"
                                data-toggle="tab"
                                href="#tab5-content"
                                role="tab"
                                (click)="activeTabFce(5)"
                                aria-controls="tab5-content"
                                aria-selected="false"
                                >{{ 'web.basic' | translate | transinit }}</a
                            >
                        </li>
                        <li class="nav-item" *ngIf="hasInfo && deviceService.isMobile()">
                            <a
                                class="nav-link px-3 px-md-5"
                                data-cy="info"
                                [class.active]="activeTab == 11"
                                id="tab11"
                                data-toggle="tab"
                                href="#tab11-content"
                                role="tab"
                                (click)="activeTabFce(11)"
                                aria-controls="tab11-content"
                                aria-selected="false"
                                >{{ 'web.participant_info' | translate | transinit }}</a
                            >
                        </li>
                        <li
                            class="nav-item"
                            *ngIf="participant?.meta?.statistics && participant.meta!.statistics!.length > 0"
                        >
                            <a
                                class="nav-link px-3 px-md-5"
                                data-cy="stats"
                                [class.active]="activeTab == 6"
                                id="tab6"
                                data-toggle="tab"
                                href="#tab6-content"
                                role="tab"
                                (click)="activeTabFce(6)"
                                aria-controls="tab6-content"
                                aria-selected="false"
                                >{{ 'web.statistics' | translate | transinit }}</a
                            >
                        </li>
                        <!-- <li class="nav-item" *ngIf="participant.news?.length > 0 && client.use('news')">
                            <a
                                class="nav-link px-3 px-md-5"
                                data-cy="news"
                                [class.active]="activeTab == 7"
                                id="tab7"
                                data-toggle="tab"
                                href="#tab7-content"
                                [routerLink]="['./']"
                                fragment="news"
                                role="tab"
                                (click)="activeTabFce(7)"
                                aria-controls="tab7-content"
                                aria-selected="false"
                                >{{ 'web.news' | translate | transinit }}</a
                            >
                        </li>-->
                        <li class="nav-item" *ngIf="isTennis && participant.balance && participant.balance.length > 0">
                            <a
                                class="nav-link px-3 px-md-5"
                                data-cy="tennisBilance"
                                [class.active]="activeTab == 8"
                                id="tab8"
                                data-toggle="tab"
                                href="#tab8-content"
                                [routerLink]="['./']"
                                fragment="tennis_bilance"
                                role="tab"
                                (click)="activeTabFce(8)"
                                aria-controls="tab8-content"
                                aria-selected="false"
                                >{{ 'web.tennis_bilance' | translate | transinit }}</a
                            >
                        </li>
                        <!-- <li class="nav-item" *ngIf="deviceService.isMobile() && lang.isActiveDefault()">
                            <a
                                class="nav-link px-3 px-md-5"
                                data-cy="did_you_know"
                                [class.active]="activeTab == 10"
                                id="tab8"
                                data-toggle="tab"
                                href="#tab10-content"
                                role="tab"
                                (click)="activeTabFce(10)"
                                aria-controls="tab8-content"
                                aria-selected="false"
                                >{{ 'web.did_you_know' | translate | transinit }}</a
                            >
                        </li> -->
                        <li *ngIf="hasStats" class="nav-item">
                            <a
                                class="nav-link px-3 px-md-5"
                                data-cy="participantStats"
                                [class.active]="activeTab == 9"
                                id="tab8"
                                data-toggle="tab"
                                href="#tab9-content"
                                role="tab"
                                (click)="activeTabFce(9)"
                                aria-controls="tab9-content"
                                aria-selected="false"
                                >{{ 'web.participant_stats' | translate | transinit }}</a
                            >
                        </li>
                        <li *ngIf="hasOptaStats" class="nav-item">
                            <a
                                class="nav-link px-3 px-md-5"
                                data-cy="participantExpectedGoals"
                                [class.active]="activeTab == 12"
                                id="tab8"
                                data-toggle="tab"
                                href="#tab12-content"
                                role="tab"
                                (click)="activeTabFce(12)"
                                aria-controls="tab12-content"
                                aria-selected="false"
                                >{{ 'web.expected_goals_season_tab' | translate }}</a
                            >
                        </li>
                    </ul>
                </div>
                <div class="tab-content bg-white px-0 px-lg-3 py-3 position-relative">
                    <div
                        class="tab-pane active"
                        [class.active]="activeTab == 1"
                        id="tab1-content"
                        role="tabpanel"
                        aria-labelledby="tab1"
                    >
                        <!--Live matches-->
                        <ng-container *ngIf="participant?.data?.live && participant.data!.live.length > 0">
                            <h6
                                class="table-sport__head bg-dark app-text-dark-tournament bg-primary--light"
                                data-toggle="collapse"
                                data-target="#collapse-1-live"
                                role="button"
                                aria-expanded="true"
                                aria-controls="collapse-today"
                            >
                                <span class="table-sport__head__text app-text-dark-tournament">{{
                                    'web.live_matches' | translate
                                }}</span>
                            </h6>
                            <div class="collapse show" id="collapse-1-live">
                                <app-component-match
                                    *ngFor="let match of participant.data.live; trackBy: trackById"
                                    page="'participant'"
                                    [match]="match"
                                    [sportName]="sportName"
                                    [sportId]="sportId"
                                    (onSelect)="loadMatch($event)"
                                ></app-component-match>
                            </div>
                        </ng-container>
                        <!--End Live matches-->
                        <!--Participant latest matches-->
                        <ng-container *ngIf="participant.data.finished && participant.data.finished.length > 0">
                            <h6
                                class="table-sport__head bg-dark text-white"
                                data-toggle="collapse"
                                data-target="#collapse-1-latest"
                                role="button"
                                aria-expanded="true"
                                aria-controls="collapse-today"
                            >
                                <span class="table-sport__head__text">{{ 'web.latest_matches' | translate }}</span>
                            </h6>
                            <div class="collapse show" id="collapse-1-latest">
                                <ng-container
                                    *ngFor="
                                        let match of participant.data.finished | slice : 0 : 15;
                                        trackBy: trackById;
                                        index as index
                                    "
                                >
                                    <div
                                        *ngIf="
                                            index === 0 ||
                                            match.sub_tournament_id !==
                                                participant.data?.finished[index - 1]?.sub_tournament_id
                                        "
                                        data-toggle="collapse"
                                        role="button"
                                        class="table-sport__subhead d-flex align-items-center py-0 bg-light"
                                        href="#collapse-53136"
                                        aria-expanded="true"
                                        aria-controls="#collapse-53136"
                                    >
                                        <!-- <img
                                            width="16"
                                            height="16"
                                            loading="lazy"
                                            class="mr-2 rounded-circle ng-star-inserted"
                                            src="/flags/4x3/world.svg"
                                            alt="Mezinárodní  Přátelské zápasy mezistátní"
                                        /> -->
                                        <div class="table-sport__subhead__text my-2 my-sm-1 text-truncate mr-auto pr-1">
                                            <span class="d-inline ng-star-inserted">{{ match.category_name }} | </span>
                                            {{ match.sub_tournament_name }}
                                        </div>
                                        <a
                                            class="xsmall text-gray d-flex d-sm-none flex-column align-items-center text-uppercase p-2 border-left border-white app-category-detail-link"
                                            [routerLink]="[
                                                '/page/sport/event/',
                                                sportName + '-' + sportId,
                                                match.tournament_id
                                            ]"
                                            [queryParams]="{ sid: match.sub_tournament_id }"
                                            ><svg class="icon icon--14 icon--gray mb-1">
                                                <use xlink:href="#icon-info"></use>
                                            </svg>
                                            {{ 'web.detail' | translate | transinit }} </a
                                        ><a
                                            class="small text-reset d-none d-sm-inline app-category-detail-link"
                                            [routerLink]="[
                                                '/page/sport/event/',
                                                sportName + '-' + sportId,
                                                match.tournament_id
                                            ]"
                                            [queryParams]="{ sid: match.sub_tournament_id }"
                                            ><svg class="icon icon--12 mr-2 d-none dm-md-inline">
                                                <use xlink:href="#icon-table"></use>
                                            </svg>
                                            {{ 'web.detail' | translate | transinit }}</a
                                        >
                                    </div>

                                    <app-component-match
                                        [dateFormat]="'localDate'"
                                        page="'participant'"
                                        [match]="match"
                                        [sportName]="sportName"
                                        [sportId]="sportId"
                                        [participantId]="data.id"
                                        (onSelect)="loadMatch($event)"
                                    ></app-component-match>
                                </ng-container>
                                <div class="text-center mt-3 mb-4">
                                    <button
                                        class="btn btn-outline-secondary btn-sm px-5"
                                        (click)="tab('participantresults')"
                                    >
                                        {{ 'web.more_results' | translate }}
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                        <!--End Participantlatest matches-->
                        <!--Participant not started matches-->
                        <ng-container *ngIf="participant.data.not_started && participant.data.not_started.length > 0">
                            <h6
                                class="table-sport__head bg-dark text-white"
                                data-toggle="collapse"
                                data-target="#collapse-1-not_started"
                                role="button"
                                aria-expanded="true"
                                aria-controls="collapse-today"
                            >
                                <span class="table-sport__head__text">{{ 'web.not_started_matches' | translate }}</span>
                            </h6>
                            <div class="collapse show" id="collapse-1-not_started">
                                <ng-container
                                    *ngFor="
                                        let match of participant.data.not_started | slice : 0 : 15;
                                        trackBy: trackById;
                                        index as index
                                    "
                                >
                                    <div
                                        *ngIf="
                                            index === 0 ||
                                            match.sub_tournament_id !==
                                                participant.data?.not_started[index - 1].sub_tournament_id
                                        "
                                        data-toggle="collapse"
                                        role="button"
                                        class="table-sport__subhead d-flex align-items-center py-0 bg-light"
                                        href="#collapse-53136"
                                        aria-expanded="true"
                                        aria-controls="#collapse-53136"
                                    >
                                        <div class="table-sport__subhead__text my-2 my-sm-1 text-truncate mr-auto pr-1">
                                            <span class="d-inline ng-star-inserted">{{ match.category_name }} | </span>
                                            {{ match.sub_tournament_name }}
                                        </div>
                                        <a
                                            class="xsmall text-gray d-flex d-sm-none flex-column align-items-center text-uppercase p-2 border-left border-white app-category-detail-link"
                                            [routerLink]="[
                                                '/page/sport/event/',
                                                sportName + '-' + sportId,
                                                match.tournament_id
                                            ]"
                                            [queryParams]="{ sid: match.sub_tournament_id }"
                                            ><svg class="icon icon--14 icon--gray mb-1">
                                                <use xlink:href="#icon-info"></use>
                                            </svg>
                                            {{ 'web.detail' | translate | transinit }} </a
                                        ><a
                                            class="small text-reset d-none d-sm-inline app-category-detail-link"
                                            [routerLink]="[
                                                '/page/sport/event/',
                                                sportName + '-' + sportId,
                                                match.tournament_id
                                            ]"
                                            [queryParams]="{ sid: match.sub_tournament_id }"
                                            ><svg class="icon icon--12 mr-2 d-none dm-md-inline">
                                                <use xlink:href="#icon-table"></use>
                                            </svg>
                                            {{ 'web.detail' | translate | transinit }}</a
                                        >
                                    </div>
                                    <app-component-match
                                        [dateFormat]="'localDateTimeShort'"
                                        page="'participant'"
                                        [match]="match"
                                        [sportName]="sportName"
                                        [sportId]="sportId"
                                        (onSelect)="loadMatch($event)"
                                    ></app-component-match>
                                </ng-container>
                                <div class="text-center mt-3 mb-4">
                                    <button
                                        class="btn btn-outline-secondary btn-sm px-5"
                                        (click)="tab('participantfuture')"
                                    >
                                        {{ 'web.more_results' | translate }}
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                        <!--End Participant notstarted matches-->
                    </div>
                    <div
                        class="tab-pane"
                        data-cy="finished-matches"
                        [class.active]="activeTab == 2"
                        id="tab2-content"
                        role="tabpanel"
                        aria-labelledby="tab2"
                    >
                        <ng-container
                            *ngFor="
                                let match of participant.data.finished | slice : 0 : finishedLimit;
                                trackBy: trackById;
                                index as index
                            "
                        >
                            <div
                                *ngIf="
                                    index === 0 ||
                                    match.sub_tournament_id !== participant.data?.finished[index - 1]?.sub_tournament_id
                                "
                                data-toggle="collapse"
                                role="button"
                                class="table-sport__subhead d-flex align-items-center py-0 bg-light"
                                href="#collapse-53136"
                                aria-expanded="true"
                                aria-controls="#collapse-53136"
                            >
                                <div class="table-sport__subhead__text my-2 my-sm-1 text-truncate mr-auto pr-1">
                                    <span class="d-inline ng-star-inserted">{{ match.category_name }} | </span>
                                    {{ match.sub_tournament_name }}
                                </div>
                                <a
                                    class="xsmall text-gray d-flex d-sm-none flex-column align-items-center text-uppercase p-2 border-left border-white app-category-detail-link"
                                    [routerLink]="[
                                        '/page/sport/event/',
                                        sportName + '-' + sportId,
                                        match.tournament_id
                                    ]"
                                    [queryParams]="{ sid: match.sub_tournament_id }"
                                    ><svg class="icon icon--14 icon--gray mb-1">
                                        <use xlink:href="#icon-info"></use>
                                    </svg>
                                    {{ 'web.detail' | translate | transinit }} </a
                                ><a
                                    class="small text-reset d-none d-sm-inline app-category-detail-link"
                                    [routerLink]="[
                                        '/page/sport/event/',
                                        sportName + '-' + sportId,
                                        match.tournament_id
                                    ]"
                                    [queryParams]="{ sid: match.sub_tournament_id }"
                                    ><svg class="icon icon--12 mr-2 d-none dm-md-inline">
                                        <use xlink:href="#icon-table"></use>
                                    </svg>
                                    {{ 'web.detail' | translate | transinit }}</a
                                >
                            </div>
                            <app-component-match
                                [dateFormat]="'localDateTimeShort'"
                                page="'participant'"
                                [match]="match"
                                [sportName]="sportName"
                                [sportId]="sportId"
                                [participantId]="data.id"
                                (onSelect)="loadMatch($event)"
                            ></app-component-match>
                        </ng-container>
                        <div class="text-center mt-3 mb-4" *ngIf="!isDone('finished', participant.data)">
                            <button class="btn btn-outline-secondary btn-sm px-5" (click)="nextResult()">
                                {{ 'web.next_result' | translate }}
                            </button>
                        </div>

                        <div *ngIf="participant.data.finished.length == 0" class="text-center">
                            <strong>{{ 'web.no_data_found' | translate }}</strong>
                        </div>
                    </div>
                    <div
                        class="tab-pane"
                        data-cy="future-matches"
                        [class.active]="activeTab == 3"
                        id="tab3-content"
                        role="tabpanel"
                        aria-labelledby="tab3"
                    >
                        <ng-container
                            *ngFor="
                                let match of participant.data.not_started | slice : 0 : notStartedLimit;
                                trackBy: trackById;
                                index as index
                            "
                        >
                            <div
                                *ngIf="
                                    index === 0 ||
                                    match.sub_tournament_id !==
                                        participant.data?.not_started[index - 1]?.sub_tournament_id
                                "
                                data-toggle="collapse"
                                role="button"
                                class="table-sport__subhead d-flex align-items-center py-0 bg-light"
                                href="#collapse-53136"
                                aria-expanded="true"
                                aria-controls="#collapse-53136"
                            >
                                <div class="table-sport__subhead__text my-2 my-sm-1 text-truncate mr-auto pr-1">
                                    <span class="d-inline ng-star-inserted">{{ match.category_name }} | </span>
                                    {{ match.sub_tournament_name }}
                                </div>
                                <a
                                    class="xsmall text-gray d-flex d-sm-none flex-column align-items-center text-uppercase p-2 border-left border-white app-category-detail-link"
                                    [routerLink]="[
                                        '/page/sport/event/',
                                        sportName + '-' + sportId,
                                        match.tournament_id
                                    ]"
                                    [queryParams]="{ sid: match.sub_tournament_id }"
                                    ><svg class="icon icon--14 icon--gray mb-1">
                                        <use xlink:href="#icon-info"></use>
                                    </svg>
                                    {{ 'web.detail' | translate | transinit }} </a
                                ><a
                                    class="small text-reset d-none d-sm-inline app-category-detail-link"
                                    [routerLink]="[
                                        '/page/sport/event/',
                                        sportName + '-' + sportId,
                                        match.tournament_id
                                    ]"
                                    [queryParams]="{ sid: match.sub_tournament_id }"
                                    ><svg class="icon icon--12 mr-2 d-none dm-md-inline">
                                        <use xlink:href="#icon-table"></use>
                                    </svg>
                                    {{ 'web.detail' | translate | transinit }}</a
                                >
                            </div>

                            <app-component-match
                                [dateFormat]="'localDateTimeShort'"
                                page="'participant'"
                                [match]="match"
                                [sportName]="sportName"
                                [sportId]="sportId"
                                (onSelect)="loadMatch($event)"
                            ></app-component-match>
                        </ng-container>
                        <div class="text-center mt-3 mb-4" *ngIf="!isDone('not_started', participant.data)">
                            <button class="btn btn-outline-secondary btn-sm px-5" (click)="nextResult()">
                                {{ 'web.next_result' | translate }}
                            </button>
                        </div>

                        <div *ngIf="participant.data.not_started.length == 0" class="text-center">
                            <strong>{{ 'web.no_data_found' | translate }}</strong>
                        </div>
                    </div>
                    <div
                        class="tab-pane"
                        [class.active]="activeTab == 4"
                        *ngIf="participant.players && participant.players.length > 0"
                        id="tab4-content"
                        role="tabpanel"
                        aria-labelledby="tab4"
                    >
                        <ng-container *ngIf="hasOptaStats">
                            <div class="mb-4">
                                <app-opta-season-player-stats
                                    [participantId]="optaTeam"
                                    [competitionId]="optaTournament"
                                    [seasonId]="optaSeason"
                                ></app-opta-season-player-stats>
                            </div>
                            <div class="mb-4">
                                <app-opta-player-compare
                                    [participantId]="optaTeam"
                                    [competitionId]="optaTournament"
                                    [seasonId]="optaSeason"
                                ></app-opta-player-compare>
                            </div>
                        </ng-container>
                        <app-players-v2
                            [data]="participant.players"
                            [sportName]="sportName"
                            [sportId]="sportId"
                            [seasonId]="getNearSeason"
                        ></app-players-v2>
                    </div>

                    <div
                        class="tab-pane"
                        [class.active]="activeTab == 5"
                        *ngIf="participant?.meta?.basic && participant.meta!.basic!.length > 0"
                        id="tab5-content"
                        role="tabpanel"
                        aria-labelledby="tab5"
                    >
                        <div class="table-responsive">
                            <div class="match-info__table small pb-2 text-center">
                                <div class="match-info__table__row player-record bg-dark text-white">
                                    <div class="match-info__table__cell text-left font-weight-bold">&nbsp;</div>
                                    <div class="match-info__table__cell text-left font-weight-bold">&nbsp;</div>
                                </div>

                                <div
                                    *ngFor="let row of participant.meta!.basic; trackBy: trackByIndex"
                                    class="match-info__table__row app-data-row-data player-record"
                                    data-cy="participant-stats-basic"
                                >
                                    <div class="match-info__table__cell text-left font-weight-bold border-top">
                                        {{ row.translation | translate }}:
                                    </div>
                                    <div class="match-info__table__cell text-left font-weight-bold border-top">
                                        {{ row.value }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="tab-pane"
                        [class.active]="activeTab == 6"
                        *ngIf="participant?.meta?.statistics && participant.meta!.statistics.length > 0"
                        id="tab6-content"
                        role="tabpanel"
                        aria-labelledby="tab6"
                    >
                        <div class="table-responsive">
                            <div class="match-info__table small pb-2 text-center">
                                <div class="match-info__table__row player-record bg-dark text-white">
                                    <div class="match-info__table__cell text-left font-weight-bold">&nbsp;</div>
                                    <div class="match-info__table__cell text-left font-weight-bold">&nbsp;</div>
                                </div>

                                <div
                                    *ngFor="let row of participant.meta!.statistics; trackBy: trackByIndex"
                                    class="match-info__table__row app-data-row-data player-record"
                                    data-cy="participant-stats-stats"
                                >
                                    <div class="match-info__table__cell text-left font-weight-bold border-top">
                                        {{ row.translation | translate }}:
                                    </div>
                                    <div class="match-info__table__cell text-left font-weight-bold border-top">
                                        {{ row.value }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="tab-pane"
                        [class.active]="activeTab == 7"
                        *ngIf="participant?.news && participant!.news!.length > 0"
                        id="tab7-content"
                        role="tabpanel"
                        aria-labelledby="tab7"
                    >
                        <app-news [data]="participant.news"></app-news>
                    </div>
                    <div
                        class="tab-pane text-center"
                        [class.active]="activeTab == 11"
                        *ngIf="hasInfo && deviceService.isMobile()"
                        id="tab11-content"
                        role="tabpanel"
                        aria-labelledby="tab11"
                    >
                        <div
                            class="d-flex align-items-center bg-white app-form-canvas mb-2"
                            *ngIf="loaded && data.jerseys && data.jerseys.length > 0"
                        >
                            <div class="col">
                                <div class="row">
                                    <div
                                        class="col-3 text-center"
                                        *ngFor="let jersey of data!.jerseys; first as isFirst; trackBy: trackByIndex"
                                    >
                                        <span class="--small"
                                            >{{ 'web.jersey_' + jersey.jerseyType | translate }}
                                        </span>
                                        <svg
                                            version="1.2"
                                            baseProfile="tiny"
                                            xmlns="http://www.w3.org/2000/svg"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 600 600"
                                            xml:space="preserve"
                                        >
                                            <g>
                                                <g>
                                                    <g>
                                                        <circle
                                                            cx="300"
                                                            cy="300"
                                                            r="300"
                                                            fill="white"
                                                            id="p_home_{{ jersey.jerseyType }}"
                                                            class="d-none app-players-field"
                                                        />
                                                        <path
                                                            [style.fill]="jerseyColor(jersey.jerseyType, 'sleeve')"
                                                            class="red2"
                                                            d="M492,117.4c0,0,53.3,21.3,53.3,106.7v64c0,0-10.7,32-106.7,21.3H161.2c-96,10.7-106.7-21.3-106.7-21.3 v-64c0-85.3,53.3-106.7,53.3-106.7H492z"
                                                        ></path>
                                                        <path
                                                            [style.fill]="jerseyColor(jersey.jerseyType, 'base')"
                                                            class="red1"
                                                            d="M385.3,53.4c30.7,28.5,67,50.3,106.7,64c0,0-64,160-64,298.7c-0.9,65.8,7.5,56.9,19.8,121.5 c2.3,10.8-4,21.6-14.6,24.8c-20.5,6.2-60.2,13.9-130.7,13.9s-110.2-7.7-130.7-13.9c-10.6-3.2-17-14-14.6-24.8 c12.3-64.6,15.6-55.7,14.7-121.5c0-138.7-64-298.7-64-298.7c39.6-13.7,76-35.5,106.7-64H385.3z"
                                                        ></path>
                                                        <g>
                                                            <path
                                                                class="white"
                                                                d="M299.9,53.4c-20.1-0.2-39.8-5.6-57.2-15.6c-4.2-3-10-2.6-13.7,1.1l-14.5,14.6c0,0,21.3,74.7,85.3,96"
                                                            ></path>
                                                            <path
                                                                class="white"
                                                                d="M299.9,53.4c20.1-0.2,39.8-5.6,57.2-15.6c4.2-3,10-2.6,13.7,1.1l14.5,14.6c0,0-21.3,74.7-85.3,96"
                                                            ></path>
                                                        </g>
                                                        <g>
                                                            <path
                                                                [style.fill]="
                                                                    jerseyColor(jersey.jerseyType, 'shirtType')
                                                                "
                                                                class="grey2"
                                                                d="M299.9,160.1c-1.1,0-2.3-0.2-3.4-0.5c-68.6-22.9-91.3-99.9-92.2-103.2c-1.1-3.7,0-7.7,2.7-10.5 l14.5-14.5c7.3-7.3,18.8-8.3,27.3-2.3c15.6,8.8,33.2,13.5,51.1,13.7c5.9,0,10.7,4.8,10.7,10.7s-4.8,10.7-10.7,10.7 c-22.3-0.2-44.1-6.2-63.3-17.5l-9.8,9.8c6.4,17.1,28.7,67.1,76.5,83c5,1.7,8,6.7,7.2,11.8S305.2,160.1,299.9,160.1z"
                                                            ></path>
                                                            <path
                                                                [style.fill]="
                                                                    jerseyColor(jersey.jerseyType, 'shirtType')
                                                                "
                                                                class="grey2"
                                                                d="M299.9,160.1c-5.2,0-9.7-3.8-10.5-9c-0.8-5.2,2.2-10.2,7.2-11.8c48-16,70.2-65.9,76.5-83l-9.8-9.8 c-19.2,11.4-41,17.5-63.3,17.7c-5.9,0-10.7-4.8-10.7-10.7s4.8-10.7,10.7-10.7c17.9-0.2,35.5-4.9,51.1-13.7 c8.5-5.9,20-5,27.3,2.3l14.5,14.5c2.7,2.7,3.8,6.7,2.7,10.5c-0.9,3.3-23.7,80.4-92.2,103.2C302.2,159.9,301.1,160.1,299.9,160.1 z"
                                                            ></path>
                                                        </g>
                                                        <g>
                                                            <path
                                                                fill="#FFFFFF"
                                                                d="M161.2,149.4c-5.2,0-9.7-3.8-10.5-9c-0.8-5.2,2.2-10.2,7.2-11.8c13.8-4.2,26.8-10.5,38.5-18.8 c4.2-4,10.8-4,15,0.1c4.1,4.1,4.2,10.8,0.1,15c-12.1,12.1-43.3,22.7-46.8,23.9C163.5,149.2,162.4,149.4,161.2,149.4z"
                                                            ></path>
                                                            <path
                                                                fill="#FFFFFF"
                                                                d="M438.6,149.4c-1.1,0-2.3-0.2-3.4-0.5c-3.5-1.2-34.7-11.8-46.8-23.9c-4-4.2-4-10.8,0.1-15 c4.1-4.1,10.8-4.2,15-0.1c11.7,8.3,24.7,14.6,38.5,18.8c5,1.7,8,6.7,7.2,11.8C448.3,145.6,443.8,149.4,438.6,149.4z"
                                                            ></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <app-participant-info
                            [participant]="data"
                            [sportId]="sportId"
                            [sportName]="sportName"
                        ></app-participant-info>
                        <div class="row">
                            <div class="col" *ngIf="hasScoredGoalsStats">
                                <iframe
                                    *ngIf="calculateScoresPeriodTotalMob"
                                    class="participant-score-iframe --mob"
                                    frameborder="0"
                                    scrolling="no"
                                    [src]="calculateScoresPeriodTotalMob | safe"
                                ></iframe>
                            </div>
                            <div class="col" *ngIf="hasScoredGoalsStats">
                                <iframe
                                    *ngIf="calculateConcatedPeriodTotalMob"
                                    class="participant-score-iframe --mob"
                                    frameborder="0"
                                    scrolling="no"
                                    [src]="calculateConcatedPeriodTotalMob | safe"
                                ></iframe>
                            </div>
                        </div>
                    </div>

                    <div
                        class="tab-pane"
                        *ngIf="activeTab == 8"
                        [class.active]="activeTab == 8"
                        id="tab8-content"
                        role="tabpanel"
                        aria-labelledby="tab8"
                    >
                        <app-tennis-bilance [bilance]="participant.balance"></app-tennis-bilance>
                    </div>
                    <div
                        class="tab-pane p-2"
                        *ngIf="activeTab == 10"
                        [class.active]="activeTab == 10"
                        id="tab10-content"
                        role="tabpanel"
                        aria-labelledby="tab8"
                    >
                        <!-- <app-qa
                            [participants]="[data.name]"
                            [random]="true"
                            [type]="aiParticipantType"
                            [sportName]="sportName"
                            [sportId]="sportId"
                            [tournaments]="uniqueTournaments"
                        ></app-qa> -->
                    </div>
                    <div
                        *ngIf="activeTab == 12"
                        class="tab-pane"
                        [class.active]="activeTab == 12"
                        id="tab12-content"
                        role="tabpanel"
                        aria-labelledby="tab12"
                    >
                        <ng-container *ngIf="hasOptaStats">
                            <app-auth-container [authOff]="!client.use('signin')" permission="stats">
                                <div class="mb-4">
                                    <app-opta-expected-goals-season
                                        [participantId]="optaTeam"
                                        [competitionId]="optaTournament"
                                        [seasonId]="optaSeason"
                                    ></app-opta-expected-goals-season>
                                </div>
                            </app-auth-container>
                        </ng-container>
                    </div>

                    <div
                        *ngIf="activeTab == 9"
                        class="tab-pane"
                        [class.active]="activeTab == 9"
                        id="tab9-content"
                        role="tabpanel"
                        aria-labelledby="tab9"
                    >
                        <ng-container *ngIf="hasOptaStats">
                            <div class="mb-4">
                                <app-opta-season-team-stats
                                    [participantId]="optaTeam"
                                    [competitionId]="optaTournament"
                                    [seasonId]="optaSeason"
                                ></app-opta-season-team-stats>
                            </div>
                        </ng-container>
                        <ul
                            class="nav nav-pills bg-light px-2 app-league-table-container app-league-table-container--big mb-4"
                        >
                            <li class="nav-item ng-star-inserted">
                                <a
                                    class="nav-link app-cursor"
                                    [class.active]="activeStats === 1"
                                    (click)="onClickStats(1)"
                                    >{{ 'web.participant_stats_global' | translate }}</a
                                >
                            </li>
                            <li class="nav-item ng-star-inserted" *ngIf="hasTableStandings">
                                <a
                                    class="nav-link app-cursor"
                                    [class.active]="activeStats === 2"
                                    (click)="onClickStats(2)"
                                >
                                    {{ 'web.participant_stats_season_standings' | translate }}
                                </a>
                            </li>
                        </ul>

                        <ng-container *ngIf="activeStats === 1; else particpantStandings">
                            <app-participant-stats [pid]="id" [sportName]="sportName">
                                <app-participant-stats-totals
                                    *ngIf="hasTotalStats"
                                    #playerStats
                                ></app-participant-stats-totals>
                                <app-participant-stats-goals
                                    *ngIf="hasGoalsStats"
                                    #playerStats
                                ></app-participant-stats-goals>
                                <app-participant-stats-uo *ngIf="hasUOStats" #playerStats></app-participant-stats-uo>
                                <app-participant-stats-match-result
                                    *ngIf="hasMatchScoreStats"
                                    #playerStats
                                ></app-participant-stats-match-result>
                                <app-participant-stats-scored-goals
                                    *ngIf="hasScoredGoalsStats"
                                    #playerStats
                                ></app-participant-stats-scored-goals>
                                <app-participant-stats-timeline
                                    *ngIf="hasTimelineStats"
                                    #playerStats
                                ></app-participant-stats-timeline>
                            </app-participant-stats>
                        </ng-container>
                        <ng-template #particpantStandings>
                            <nav class="p-1">
                                <ol class="app-small-nav">
                                    <li
                                        class="crumb ng-star-inserted"
                                        *ngFor="let item of this.participantTableSeason; trackBy: trackByIndex"
                                    >
                                        <a
                                            class="crumb__link active app-cursor"
                                            (click)="activeStatsSub = item[2]"
                                            [class.active]="item[2] === activeStatsSub"
                                            >{{ item[1] }} ({{ item[0] }})</a
                                        >
                                    </li>
                                </ol>
                            </nav>
                            <div class="d-flex table-scrollable">
                                <div class="table-scrollable__data flex-grow-1" tableScroll>
                                    <iframe
                                        *ngIf="calculateTableStandingsPath"
                                        class="participant-standings-iframe"
                                        frameborder="0"
                                        scrolling="no"
                                        [src]="calculateTableStandingsPath + '?lang=' + lang.getLangSnapshot() | safe"
                                    ></iframe>
                                </div>
                            </div>
                        </ng-template>
                    </div>

                    <div *ngIf="client.use('seoCard') && data?.participant_info" class="card shadow border-0 mt-5">
                        <div class="app-info-card card-body p-0 p-md-5">
                            <div class="media">
                                <svg class="icon icon--32 mr-3">
                                    <use href="#icon-bulp-2" />
                                </svg>
                                <div class="media-body mt-2">
                                    <h6 class="font-weight-bold text-uppercase">{{ data?.name }}</h6>
                                    <div [innerHTML]="data?.participant_info | markdown"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <google-modal [mapCoordinates]="participant.venue?.stadium_coords" [(visible)]="map"></google-modal>
            </ng-container>
            <ng-template #elseBlock>
                <app-placeholder-tournament [progress]="false"></app-placeholder-tournament>
            </ng-template>
        </div>
        <!-- Col Right -->
        <div class="col-lg-4 d-none d-lg-block" [ngClass]="matchClass">
            <div
                *ngIf="false && loaded && data?.goals_scored_conceded && data!.goals_scored_conceded!.length > 0"
                class="row text-center col-right__top px-lg-6 pt-4 goals-scored-conceded"
                data-cy="goals-scored-conceded"
            >
                <div class="row w-100">
                    <div class="col-6 pt-2">
                        <h5 class="text-uppercase font-weight-bold">{{ 'web.goals' | translate }}</h5>
                    </div>
                    <div class="col-6">
                        <select class="custom-select border-light2 mb-3" [(ngModel)]="gscTournamentIndex">
                            <option value="0">{{ 'web.select_tournament' | translate }}</option>
                            <option *ngFor="let gsc of data!.goals_scored_conceded; let i = index" [value]="i">
                                {{ gsc.tournament_name }}
                                {{ gsc.startYear == gsc.endYear ? gsc.startYear : gsc.startYear + '/' + gsc.endYear }}
                            </option>
                        </select>
                    </div>
                </div>
                <app-goals-scored-received
                    data-cy="app-goals-scored-received"
                    [data]="data.goals_scored_conceded[gscTournamentIndex]"
                ></app-goals-scored-received>
            </div>

            <div class="d-flex bg-white align-items-center" *ngIf="loaded && hasInfo">
                <div class="col">
                    <div class="text-center p-2 h-100">
                        <app-participant-info
                            [participant]="data"
                            [sportId]="sportId"
                            [sportName]="sportName"
                        ></app-participant-info>
                    </div>
                </div>
            </div>

            <div
                class="d-flex align-items-center bg-white app-form-canvas p-3"
                *ngIf="loaded && data.jerseys && data.jerseys.length > 0"
            >
                <div class="col">
                    <div class="row">
                        <div
                            class="col-3 text-center"
                            *ngFor="let jersey of data!.jerseys; first as isFirst; trackBy: trackByIndex"
                        >
                            <span class="--small">{{ 'web.jersey_' + jersey.jerseyType | translate }} </span>
                            <svg
                                version="1.2"
                                baseProfile="tiny"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                viewBox="0 0 600 600"
                                xml:space="preserve"
                            >
                                <g>
                                    <g>
                                        <g>
                                            <circle
                                                cx="300"
                                                cy="300"
                                                r="300"
                                                fill="white"
                                                id="p_home_{{ jersey.jerseyType }}"
                                                class="d-none app-players-field"
                                            />
                                            <path
                                                [style.fill]="jerseyColor(jersey.jerseyType, 'sleeve')"
                                                class="red2"
                                                d="M492,117.4c0,0,53.3,21.3,53.3,106.7v64c0,0-10.7,32-106.7,21.3H161.2c-96,10.7-106.7-21.3-106.7-21.3 v-64c0-85.3,53.3-106.7,53.3-106.7H492z"
                                            ></path>
                                            <path
                                                [style.fill]="jerseyColor(jersey.jerseyType, 'base')"
                                                class="red1"
                                                d="M385.3,53.4c30.7,28.5,67,50.3,106.7,64c0,0-64,160-64,298.7c-0.9,65.8,7.5,56.9,19.8,121.5 c2.3,10.8-4,21.6-14.6,24.8c-20.5,6.2-60.2,13.9-130.7,13.9s-110.2-7.7-130.7-13.9c-10.6-3.2-17-14-14.6-24.8 c12.3-64.6,15.6-55.7,14.7-121.5c0-138.7-64-298.7-64-298.7c39.6-13.7,76-35.5,106.7-64H385.3z"
                                            ></path>
                                            <g>
                                                <text
                                                    x="250"
                                                    y="350"
                                                    style="font-size: 100px"
                                                    [style.fill]="jerseyColor(jersey.jerseyType, 'number')"
                                                >
                                                    1
                                                </text>
                                            </g>
                                            <g>
                                                <path
                                                    class="white"
                                                    d="M299.9,53.4c-20.1-0.2-39.8-5.6-57.2-15.6c-4.2-3-10-2.6-13.7,1.1l-14.5,14.6c0,0,21.3,74.7,85.3,96"
                                                ></path>
                                                <path
                                                    class="white"
                                                    d="M299.9,53.4c20.1-0.2,39.8-5.6,57.2-15.6c4.2-3,10-2.6,13.7,1.1l14.5,14.6c0,0-21.3,74.7-85.3,96"
                                                ></path>
                                            </g>
                                            <g>
                                                <path
                                                    [style.fill]="jerseyColor(jersey.jerseyType, 'shirtType')"
                                                    class="grey2"
                                                    d="M299.9,160.1c-1.1,0-2.3-0.2-3.4-0.5c-68.6-22.9-91.3-99.9-92.2-103.2c-1.1-3.7,0-7.7,2.7-10.5 l14.5-14.5c7.3-7.3,18.8-8.3,27.3-2.3c15.6,8.8,33.2,13.5,51.1,13.7c5.9,0,10.7,4.8,10.7,10.7s-4.8,10.7-10.7,10.7 c-22.3-0.2-44.1-6.2-63.3-17.5l-9.8,9.8c6.4,17.1,28.7,67.1,76.5,83c5,1.7,8,6.7,7.2,11.8S305.2,160.1,299.9,160.1z"
                                                ></path>
                                                <path
                                                    [style.fill]="jerseyColor(jersey.jerseyType, 'shirtType')"
                                                    class="grey2"
                                                    d="M299.9,160.1c-5.2,0-9.7-3.8-10.5-9c-0.8-5.2,2.2-10.2,7.2-11.8c48-16,70.2-65.9,76.5-83l-9.8-9.8 c-19.2,11.4-41,17.5-63.3,17.7c-5.9,0-10.7-4.8-10.7-10.7s4.8-10.7,10.7-10.7c17.9-0.2,35.5-4.9,51.1-13.7 c8.5-5.9,20-5,27.3,2.3l14.5,14.5c2.7,2.7,3.8,6.7,2.7,10.5c-0.9,3.3-23.7,80.4-92.2,103.2C302.2,159.9,301.1,160.1,299.9,160.1 z"
                                                ></path>
                                            </g>
                                            <g>
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M161.2,149.4c-5.2,0-9.7-3.8-10.5-9c-0.8-5.2,2.2-10.2,7.2-11.8c13.8-4.2,26.8-10.5,38.5-18.8 c4.2-4,10.8-4,15,0.1c4.1,4.1,4.2,10.8,0.1,15c-12.1,12.1-43.3,22.7-46.8,23.9C163.5,149.2,162.4,149.4,161.2,149.4z"
                                                ></path>
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M438.6,149.4c-1.1,0-2.3-0.2-3.4-0.5c-3.5-1.2-34.7-11.8-46.8-23.9c-4-4.2-4-10.8,0.1-15 c4.1-4.1,10.8-4.2,15-0.1c11.7,8.3,24.7,14.6,38.5,18.8c5,1.7,8,6.7,7.2,11.8C448.3,145.6,443.8,149.4,438.6,149.4z"
                                                ></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="d-flex bg-white align-items-center app-form-canvas"
                *ngIf="data?.data?.finished && data!.data!.finished!.length > 0"
            >
                <div class="col">
                    <div *ngIf="loaded" class="text-center p-2 h-100">
                        <app-participant-form [form]="formFiltered | slice : 0 : 10"></app-participant-form>
                    </div>
                </div>
                <div class="col" *ngIf="hasScoredGoalsStats">
                    <iframe
                        *ngIf="calculateScoresPeriodTotal"
                        class="participant-score-iframe"
                        frameborder="0"
                        scrolling="no"
                        [src]="calculateScoresPeriodTotal | safe"
                    ></iframe>
                </div>
            </div>

            <ng-container *ngIf="matchId">
                <app-switchers (onStickyChange)="onStickyChange($event)"></app-switchers>
                <div
                    [class.sticky-parent]="stickyDetail"
                    class="col-right__inner bg-white"
                    *ngIf="matchId"
                    data-cy="right-column-match"
                >
                    <app-match-detail
                        class="sticky-parent__match"
                        data-cy="participant-match-detail"
                        [class.app-match-detail--shadow]="stickyDetail"
                        [style.max-height]="viewPortHeightMatchDetail"
                        [id]="matchId"
                        [matchState]="matchState"
                        [tracker]="tracker"
                        [stream]="stream"
                    ></app-match-detail>
                </div>
            </ng-container>
            <div *ngIf="data?.name && !deviceService.isMobile()" class="d-flex bg-white align-items-center">
                <div class="col">
                    <!-- <app-qa
                        [participants]="[data.name]"
                        [random]="true"
                        [type]="aiParticipantType"
                        [sportName]="sportName"
                        [sportId]="sportId"
                        [tournaments]="uniqueTournaments"
                    ></app-qa> -->
                </div>
            </div>
            <div class="d-flex bg-white align-items-center app-form-canvas" *ngIf="data?.season_stats">
                <div class="col">
                    <hr />
                    <nav>
                        <ol class="app-small-nav">
                            <li
                                class="crumb"
                                *ngFor="let stats of data!.season_stats | keys; first as isFirst; trackBy: trackByIndex"
                            >
                                <a
                                    [routerLink]="['./']"
                                    [queryParams]="{ statsKey: stats.key }"
                                    class="crumb__link"
                                    [class.active]="
                                        seasonStatsParam === stats.key || (seasonStatsParam === null && isFirst)
                                    "
                                    >{{ stats.value.tournamentName }}</a
                                >
                            </li>
                        </ol>
                    </nav>
                    <ng-container
                        *ngFor="let stats of data?.season_stats | keys; first as isFirst; trackBy: trackByIndex"
                    >
                        <app-participant-season-stats
                            *ngIf="seasonStatsParam === stats.key || (seasonStatsParam === null && isFirst)"
                            [stats]="stats.value"
                        ></app-participant-season-stats>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <aside class="nav-sports d-none d-xl-block" *ngIf="client.use('sportQuickNav')">
        <nav class="nav flex-column bg-light">
            <app-sport-navigation type="aside" [limit]="asideLimit" [sportId]="sportId"></app-sport-navigation>
            <a
                class="nav-link nav-link--more text-uppercase text-reset"
                [class.nav-link--more]="isAsideDefault()"
                [class.nav-link--less]="!isAsideDefault()"
                (click)="toggleAssideLimit()"
            >
                <span *ngIf="isAsideDefault()">{{ 'web.more' | translate }}</span>
                <span *ngIf="!isAsideDefault()">{{ 'web.less' | translate }}</span>
            </a>
        </nav>
    </aside>
    <aside [class.d-xl-block]="client.use('sportQuickNav')" class="nav-sports nav-sports--extra d-none">
        <nav class="nav flex-column nav-side-menu bg-light">
            <app-side-menu-navigation [sportId]="sportId" [sportName]="sportName"></app-side-menu-navigation>
        </nav>
    </aside>
</div>

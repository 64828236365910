/* eslint-disable @typescript-eslint/naming-convention */
/**
 *  Match list page
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import { Component, OnInit, OnDestroy, NgZone, AfterViewInit } from '@angular/core';
import { Subscription, timer, of, interval, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'underscore';
import $ from 'src/app/shared/jquery';
import * as $$ from 'jquery';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { PageMixin } from 'src/app/services/mixins/page.mixin';
import { LiveDatePipe } from 'src/app/shared/pipes/live-datetime.pipe';
import { DeviceDetectorService } from 'ngx-device-detector';
import SPORT_CONFIG from 'src/app/config/sport.config';
import APP_CONFIG from 'src/app/config/app.config';
import { delay, switchMap, take } from 'rxjs/operators';
import { StorageService } from 'src/app/services/storage.service';

import { ViewportScroller } from '@angular/common';

import { WindowRefService } from '@shared/window-ref';

import { environment } from '../../../../environments/environment';
import { BaseComponent } from '../../base.component';
import { PageService } from '../../../services/page.service';
import { MatchListService } from '../../../services/match-list.service';
import { TimeService } from '../../../services/time.service';
import { CategoryInterface } from '../../../interfaces/category.interface';
import { MatchData, MatchState } from '../../../interfaces/match-data.interface';
import { UtilsService } from '../../../services/utils.service';
import { ClientService } from '../../../services/client.service';
import { FavoriteService } from '../../../services/favorite.service';
import { LangService } from '../../../services/lang.service';
import { InfoService } from '../../../services/info.service';
import { MetaService } from '../../../services/meta.service';

import { PartnerService } from '@/services/partner.service';
import { MatchTab } from '@/interfaces/match-list.interface';

declare const moment: any;


@Component({
    selector: 'app-match-list',
    templateUrl: './match-list.component.html',
})
export class MatchListComponent extends PageMixin(BaseComponent)
    implements OnInit, AfterViewInit, OnDestroy {
    public matchTab = MatchTab;

    public sportId: number;

    public sportName: string;

    public data: CategoryInterface[];

    /**
     * Data for each category
     */
    public categoryData: { [prop: number]: MatchData[] };

    public categoryGroupData: { [prop: number]: CategoryInterface[] };

    private openCategories: string[] = [];

    public matchId: number | null = null;

    public parentClass: Record<string, boolean> = { 'col-lg-12': false, 'col-lg-8': true };

    public matchClass: Record<string, boolean> = { 'col-lg-4': true, 'd-lg-block': true };

    public tracker: boolean = false;
    public stream: boolean = false;

    public detailCaption: string = '';

    public date: string | null = null;

    public paramDate: string | any | null = null;

    public alphaAvailLetters: string[] = [];

    public alphaFilter: { star: boolean; alpha: string[] } | undefined;

    public scoreActive = false;

    private subscriber: Subscription;

    private subscriber2: Subscription;

    private subscriberReload: Subscription;

    private subscriberTab: Subscription;

    private subscriberSlice: Subscription;


    private from: any;

    private to: any;

    public lastTabVisible: any;

    public type: MatchTab;

    private subTournamets: number[] = [];

    public slice: number = APP_CONFIG.matchListSlice;
    public sliceMatch: number = APP_CONFIG.matchListSlice4;
    public slice2: number;

    public categoryGroup: CategoryInterface[] = [];

    public matchState: MatchState;

    /**
     * Switch group category on/off
     */
    public isGroupCategory: boolean = false;

    /**
     * number of sport aside menu
     */
    public asideLimit: number = SPORT_CONFIG.asideSportDefaultNum;

    /**
     * First data load
     */
    public first: boolean;

    public firstDefault: boolean;

    public routeChange: boolean = true;

    private firstInterval: number;

    private openAll: boolean | null = null;

    private io: IntersectionObserver;
    private boxEl: Element | null;

    private fce: () => void;

    public constructor(
        protected page: PageService,
        private router: ActivatedRoute,
        private matchList: MatchListService,
        private favorite: FavoriteService,
        protected title: Title,
        protected meta: Meta,
        protected route: Router,
        protected translate: TranslateService,
        public client: ClientService,
        protected lang: LangService,
        protected info: InfoService,
        public deviceService: DeviceDetectorService,
        private storage: StorageService,
        public partner: PartnerService,
        private viewportScroller: ViewportScroller,
        public zone: NgZone,
        private metaService: MetaService,
        private windowRefService: WindowRefService,


    ) {
        super(page, title, meta, route, info);
    }

    public ngAfterViewInit(): void {
        this.lang.isLangLoaded().pipe(delay(1000)).subscribe(() => {
            $$('.app-mob-nav-wrapper .nav-tabs').scrollLeft(0);
        });
    }


    private intersectionObserver(): void {
    // Register IntersectionObserver
        this.io = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.intersectionRatio > 0) {
                    this.zone.run(() => {
                        this.slice2 += this.deviceService.isMobile()
                            ? APP_CONFIG.matchListSlice2 : APP_CONFIG.matchListSlice3;
                    });
                }
            });
        });

        // Declares what to observe, and observes its properties.


        if (this.boxEl) {
            this.io.observe(this.boxEl);
        }
    }

    get hasRanking(): boolean {
        return SPORT_CONFIG.rankings.table.includes(this.sportName);
    }

    get isTennis(): boolean {
        return SPORT_CONFIG.isTennis(this.sportName);
    }

    public ngOnInit(): void {
        this.firstDefault = false;

        super.ngOnInit();

        this.subscriberTab = this.tabSubject.subscribe((val: string): void => {
            if (val === 'visible') {
                this.load();
            }
        });


        const { hostname } = this.windowRefService.nativeWindow.location;
        const jsonLD = {
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            url: `https://${hostname}`,
            potentialAction: {
                '@type': 'SearchAction',
                target: {
                    '@type': 'EntryPoint',
                    urlTemplate: `https://${hostname}/page/sport/match-list/soccer-5?q={search_term_string}`,
                },
                'query-input': 'required name=search_term_string',
            },
        };
        this.metaService.jsonLDSetter$.next(jsonLD);


        this.router.paramMap.subscribe((params): void => {
            this.reset();
            this.start();
            this.destroyAll();
            this.scoreActive = false;
            this.viewportScroller.scrollToPosition([0, 0]);
            this.slice = APP_CONFIG.matchListSlice;


            this.slice2 = this.deviceService.isMobile() ? APP_CONFIG.matchListSlice2 : APP_CONFIG.matchListSlice3;


            $('[data-toggle="tooltip"]').tooltip('hide');
            this.data = [];
            this.alphaAvailLetters = [];
            this.alphaFilter = undefined;
            this.matchId = null;
            this.parentClass = { 'col-lg-12': false, 'col-lg-8': true };
            this.matchClass = { 'col-lg-4': true, 'd-lg-block': true };
            this.openCategories.splice(0);
            this.first = true;
            this.routeChange = true;
            this.firstDefault = false;
            this.categoryData = {};
            this.categoryGroupData = {};

            if (params.has('sport')) {
                [this.sportId, this.sportName] = UtilsService.parseSportUrl(params.get('sport')!);
                const date = params.has('date')
                    ? TimeService.getLocalTime(params.get('date')!, TimeService.dateFormat)
                    : moment();
                this.paramDate = params.has('date') ? params.get('date') : null;
                this.from = TimeService.startLocal(moment(date));
                this.to = TimeService.endLocal(moment(date));

                const type = params.get('type') as MatchTab;
                this.type = params.has('type') ? type : MatchTab.ALL;
                if (this.matchList.lastTab === this.matchTab.RANKING) {
                    this.changeTab(this.type);
                }


                this.loader();
            } else {
                this.error();
            }

            this.sliceMatch = SPORT_CONFIG.isTableTennis(this.sportName)
                ? APP_CONFIG.matchListSlice4TableTennis : APP_CONFIG.matchListSlice4;

            this.lang.getLang().then((): Promise<any> => this.createTitle());

            if (this.matchList.lastTab) {
                this.changeTab(this.matchList.lastTab);
            } else {
                this.changeTab(MatchTab.ALL);
            }
        });

        this.favorite.onToggleTournament.subscribe((): void => {
            this.load(true);
        });


        this.method()();

        window.addEventListener('scroll', this.method());
    }

    public ngOnDestroy(): void {
        this.scoreActive = false;
        if (this.subscriberTab instanceof Subscription) {
            this.subscriberTab.unsubscribe();
        }

        if (this.io) {
            this.io.disconnect();
        }
        this.destroyAll();
        $('[data-toggle="tooltip"]').tooltip('hide');


        window.removeEventListener('scroll', this.fce);
        // eslint-disable-next-line no-extra-parens
        (document.querySelector('.navbar') as HTMLElement).style.top = '0px';
    }

    public openAllMatches(): void {
        this.sliceMatch += (SPORT_CONFIG.isTableTennis(this.sportName)
            ? APP_CONFIG.matchListSlice4TableTennis : APP_CONFIG.matchListSlice4);
    }

    get isAllMatchOpen(): boolean {
        return this.sliceMatch === APP_CONFIG.matchListSlice4Open;
    }

    method(): () => void {
        let prevY = window.pageYOffset;

        if (!this.fce) {
            this.fce = (event = null): void => {
                const currentY = window.pageYOffset;

                let changeLastValue = true;

                if (prevY < currentY) {
                    if (currentY > 100) {
                    // eslint-disable-next-line no-extra-parens
                        (document.querySelector('.navbar') as HTMLElement).style.top = '-100px';
                    }
                } else if (prevY - 110 > currentY || currentY < 100) {
                    // eslint-disable-next-line no-extra-parens
                    (document.querySelector('.navbar') as HTMLElement).style.top = '0px';
                } else {
                    changeLastValue = false;
                }

                if (event) {
                    const cardEl = (document.querySelector('.app-info-card-parent') as HTMLElement) ?? undefined;
                    let cardHeight = 0;
                    if (cardEl) {
                        const stylesCard = window.getComputedStyle(cardEl);
                        const marginCard = parseFloat(stylesCard.marginTop) +
                       parseFloat(stylesCard.marginBottom);
                        cardHeight = Math.ceil(cardEl.offsetHeight + marginCard);
                    }

                    // eslint-disable-next-line no-extra-parens
                    const el = (document.querySelector('.app-footer') as HTMLElement);

                    const styles = window.getComputedStyle(el);
                    const margin = parseFloat(styles.marginTop) +
               parseFloat(styles.marginBottom);

                    const footerHeight = Math.ceil(el.offsetHeight + margin);
                    if (+currentY + +window.innerHeight + 250 + footerHeight + cardHeight >=
                            document.body.scrollHeight) {
                        this.zone.run(() => {
                            this.slice2 += this.deviceService.isMobile()
                                ? APP_CONFIG.matchListSlice2 : APP_CONFIG.matchListSlice3;
                        });
                    }
                }

                if (changeLastValue) {
                    prevY = currentY;
                }
            };
        }

        return this.fce;
    }

    get isNotAllLoaded(): boolean {
        return this.slice2 < this.data.length;
    }


    /**
     * Create page title and meta
     * @return Promise<any>
     */
    private createTitle(): any {
        this.translate.get('web.title_sport_detail').subscribe((): void => {
            const sport = this.translate.instant(`sport.${this.sportName}`);
            const trans = this.translate.instant('web.title_sport_detail');
            const desc = this.translate.instant('web.description_sport_detail');
            const client = this.client.getVName();
            this.setTitle(
                {
                    title: `${sport} - ${trans} | ${client}`,
                    description: `${sport} ${desc}`,
                },
                true,
            );
        });
    }

    /**
     * Load data
     */
    private loader(): void {
        this.destroyAll();
        //  this.firstLoadHandler().subscribe({
        //     complete: (): void => {
        //         this.subscriberReload = timer(
        //             0,
        //             environment.matchListReloadTime
        //         ).subscribe((): Promise<void> => this.load());
        //     },
        // });

        const timerInterval =
            this.type === MatchTab.LIVE ? environment.matchListLiveReloadTime : environment.matchListReloadTime;
        this.subscriberReload = timer(0, timerInterval)
            .pipe(switchMap((t): any => of(t)))
            .subscribe((): Promise<void> => this.load());
    }

    /**
     * Toggle aside menu
     */
    public toggleAssideLimit(): void {
        this.asideLimit =
            this.asideLimit === SPORT_CONFIG.asideSportDefaultNum ? 12 : SPORT_CONFIG.asideSportDefaultNum;
    }

    /**
     * Check if aside menu is closed
     * @return {boolean}
     */
    public isAsideDefault(): boolean {
        return this.asideLimit === SPORT_CONFIG.asideSportDefaultNum;
    }

    get isInPast(): boolean {
        if (this.paramDate) {
            const date = moment(this.paramDate);
            return date.isBefore(moment().format('YYYY-MM-DD'));
        }
        return false;
    }

    /**
     * Destroy all subscribers
     * @return {void}
     */
    private destroyAll(): void {
        this.data = [];
        clearInterval(this.firstInterval);
        if (this.subscriberReload instanceof Subscription) {
            this.subscriberReload.unsubscribe();
        }

        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }
        if (this.subscriber2 instanceof Subscription) {
            this.subscriber2.unsubscribe();
        }
        if (this.subscriberSlice instanceof Subscription) {
            this.subscriberSlice.unsubscribe();
        }

        // if (this.deviceService.isMobile()) {
        //     this.slice = environment.matchListMobileLimit;
        // }
    }

    private destroyPart(): void {
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }
        if (this.subscriber2 instanceof Subscription) {
            this.subscriber2.unsubscribe();
        }
    }

    public trackByFn(index: number, item: Record<string, any>): number | null {
        if (_.isUndefined(item)) {
            return null;
        }
        return item.id;
    }

    /**
     * Date change event
     * @event MatchListComponent#dateChange
     * @param  date [description]
     * @return      [description]
     */
    public dateChange(date: any): void {
        this.date = date.format(TimeService.dateFormat);
        this.route.navigate(['page', 'sport', 'match-list', `${this.sportName}-${this.sportId}`, { date: this.date }]);
    }

    public changeTab(tab: MatchTab = MatchTab.ALL): void {
        this.type = tab;
        this.matchList.lastTab = tab;
        this.first = true;
        this.openAll = false;
        this.firstDefault = false;
        this.alphaFilter = undefined;
        this.reset();
        this.data = [];
        this.categoryData = {};
        this.categoryGroupData = {};
        this.alphaAvailLetters = [];
        this.subTournamets = [];
        this.loader();
        this.matchList.alphaReset.emit(true);

        this.windowRefService.nativeWindow.dataLayer.push({
            event: 'click_tab',
            tab_name: tab,
            tab_sport: this.sportName,

        });

        if (tab !== MatchTab.LIVE) {
            this.routeChange = true;
        }

        if (this.isGroupCategory) {
            if (tab === 'live') {
                this.categoryGroup.forEach((v): void => {
                    v.isopen = true;
                });
            } else {
                this.categoryGroup.forEach((v): void => {
                    v.isopen = false;
                });
            }
        }
    }

    /**
     * Format UTC datetime to date dd.mm.YYYY
     * @param  {string} date
     * @return {string}
     */
    public setDate(date: string): string {
        const obj = new LiveDatePipe();
        return obj.transform(date, 'datedb');
    }

    /**
     * Check if data for suboturnamen texists
     * @param  {number} subTournamentId
     * @return    {MatchData[]}
     */
    public getData(subTournamentId: number): MatchData[] {
        return (this.categoryData && this.categoryData[subTournamentId]) || [];
    }

    /**
     * Event fired when some match is selected/clicked
     * @event MatchListComponent#loadMatch
     * @param  match
     * @param  {boolean} updateCaption
     * @return {void}
     */
    public loadMatch(match: any, updateCaption: boolean = true): void {
        if (updateCaption) {
            this.detailCaption = '';
        }
        if (_.has(match, 'stream')) {
            this.stream = match.stream as boolean;
        }

        if (this.matchId != null && this.matchId === match.id) {
            return;
        }
        // TODO - hide match detail od second click
        // if (this.matchId != null && this.matchId
        // === match.id) {
        //     this.parentClass["col-lg-8"] = false;
        //     this.parentClass["col-lg-12"] = true;
        //     this.matchClass["d-lg-block"] = false;
        //     this.matchId = null;
        //     return;
        // }
        this.parentClass['col-lg-8'] = true;
        this.parentClass['col-lg-12'] = false;
        this.matchClass['d-lg-block'] = true;
        this.matchState = match.match_state;
        this.matchId = match.id;
        this.tracker = false;

        if (_.has(match, 'tracker') && match.tracker === true) {
            this.tracker = true;
        }
    }

    public get oneline(): boolean {
        return SPORT_CONFIG.displayType.sets.includes(this.sportName) === false && !this.isMobile;
    }


    /**
     * Open default match on first load
     * @return {void}
     */
    private openDefault(): void {
        if (_.isUndefined(this.data) || _.isUndefined(this.data[0])) {
            this.matchId = null;
            this.parentClass['col-lg-12'] = true;
            this.parentClass['col-lg-8'] = false;
            this.matchClass['d-lg-block'] = false;
        } else {
            this.parentClass['col-lg-8'] = true;
            this.parentClass['col-lg-12'] = false;
            this.matchClass['d-lg-block'] = true;
        }

        if (
            this.deviceService.isMobile() ||
            this.firstDefault ||
            _.isUndefined(this.data) ||
            _.isUndefined(this.data[0])
        ) {
            return;
        }
        const data =
            this.data && this.categoryData[this.data[0].sub_tournament_id]
                ? this.categoryData[this.data[0]!.sub_tournament_id]![0]
                : false;

        if (data) {
            this.loadMatch(data, true);
            this.page.onMatchActive.next(data.id);
            this.firstDefault = true;
        }
    }

    /**
     * Check if there is no category data
     * @return [description]
     */
    public noCategory(): boolean {
        return !_.isArray(this.data);
    }

    public showMoreResults(): void {
        this.slice += environment.matchListMobileLimit;
    }

    /**
     * Chekc if there is more results to show
     * @return {boolean}
     */
    public isMoreResults(): boolean {
        return this.data.length > this.slice;
    }

    private filtrAlpha(data: any): Promise<string[]> {
        return new Promise((resolve): void => {
            const ret: string[] = [];
            if (_.isArray(data)) {
                data.forEach((v): void => {
                    if (SPORT_CONFIG.isSet(this.sportName)) {
                        if (v.sub_tournament_name !== null) {
                            ret.push(v.sub_tournament_name.charAt(0).toLowerCase());
                        }
                    } else if (v.category_name !== null) {
                        ret.push(v.category_name.charAt(0).toLowerCase());
                    }
                });
            }

            resolve(ret);
        });
    }

    private isCategoryOpen(subId: number): boolean {
        const openCategories = this.storage.get<any[]>(SPORT_CONFIG.categoryIndex) || [];

        const index = openCategories.findIndex((val): boolean => val === subId);

        return index !== -1;
    }

    /**
     * Manage which categories will be open in which situation
     */
    private handleOpenCategories(c: CategoryInterface, key: number): void {
        const defaultOpen =
            SPORT_CONFIG.isTableTennis(this.sportName) ? 1 : SPORT_CONFIG.matchListDefaultOpenCategories;

        if (!this.deviceService.isMobile() && this.type !== 'live' && key < defaultOpen) {
            c.isopen = true;
        } else if (!this.deviceService.isMobile() && this.type === 'live' && key < 8) {
            c.isopen = true;
        } else if (this.deviceService.isMobile() && this.type !== 'live' && key < defaultOpen) {
            c.isopen = true;
        } else if (this.deviceService.isMobile() && this.type === 'live' && key < defaultOpen) {
            c.isopen = true;
        } else if (this.openAll) {
            c.isopen = true;
        } else {
            c.isopen = false;
        }

        if (this.isCategoryOpen(c.sub_tournament_id)) {
            c.isopen = true;
        } else if (this.favorite.existsTournament(this.sportId, c.tournament_id)) {
            c.isopen = true;
        }
    }

    public onOpenChange(event: any): void {
        this.data = this.data.map(
            (val): CategoryInterface => {
                val.isopen = event;
                return val;
            },
        );

        this.openAll = event;
        this.load(true);
    }

    /**
     * Map category to category groups
     * @param  {CategoryInterface[]} category
     * @return  {CategoryInterface[]}
     */
    private getCategoryGroup(category: CategoryInterface[]): CategoryInterface[] {
        const ret: Record<string, any>[] = [];
        category.forEach((cat): void => {
            const find = ret.find((val): boolean => val.category_id === cat.category_id);

            if (!find) {
                const found = this.categoryGroup.find((val2): boolean => val2.category_id === cat.category_id);

                const saved = this.storage.get<number[]>('APP_GROUP_MATCHES');
                const found2 = saved ? saved.includes(cat.category_id) : false;

                const shouldBeOpen: boolean =
                    this.deviceService.isMobile() &&
                    // @ts-ignore
                    SPORT_CONFIG.displayType.groupCategories.includes(this.sportName) &&
                    this.type === 'live';
                if (this.first && (found2 || shouldBeOpen)) {
                    cat.isopen = true;
                } else if (found) {
                    cat.isopen = found.isopen;
                } else {
                    cat.isopen = false;
                }
                ret.push(cat);
            }
        });
        return JSON.parse(JSON.stringify(ret));
    }

    public get isMobile(): boolean {
        return this.deviceService.isMobile();
    }

    public get isComparition(): boolean {
        return !this.isMobile && SPORT_CONFIG.isComparitionForm(this.sportName);
    }


    /**
     * Load tournament categories
     */
    protected async load(updateAll: boolean = false,
        data: Observable<CategoryInterface[]> | null = null): Promise<any> {
        try {
            if (this.tabVisible && this.lastTabVisible === false) {
                updateAll = true;
            }

            this.lastTabVisible = this.tabVisible;

            if (!this.tabVisible) {
                return;
            }

            data = await
            this.matchList.getCategories(this.sportId, this.type, this.from, this.to, 'alpha', this.first);

            this.subscriber = data.subscribe(
                (val): any => {
                    if (updateAll) {
                        this.first = true;
                    }

                    if (_.isArray(this.data) && _.isArray(val)) {
                        if (this.deviceService.isMobile()) {
                            this.categoryGroup = this.getCategoryGroup(val);
                            this.isGroupCategory =
                                this.deviceService.isMobile() &&
                                // @ts-ignore
                                SPORT_CONFIG.displayType.groupCategories.includes(this.sportName) &&
                                this.categoryGroup.length > 1;
                            this.categoryGroupData = _.groupBy(val, (val2): number => val2.category_id);
                        }

                        this.data = val.map(
                            (c, key): CategoryInterface => {
                                if (this.first) {
                                    this.handleOpenCategories(c, key);
                                }
                                const index = this.data.findIndex(
                                    (v: any): boolean => v.sub_tournament_id === c.sub_tournament_id &&
                                        v.category_id === c.category_id,
                                );
                                if (index !== -1) {
                                    c.isopen = this.data[index]!.isopen;
                                }
                                return c;
                            },
                        );
                    } else {
                        this.data = val;
                    }

                    this.filtrAlpha(this.data).then((data2): void => {
                        this.alphaAvailLetters = data2;
                    });

                    if (this.first && !updateAll && this.routeChange && this.data.length > APP_CONFIG.matchListSlice) {
                        this.slice = APP_CONFIG.matchListSlice;
                        this.subscriberSlice = interval(300)
                            .pipe(take(3))
                            .subscribe((i) => {
                                if (i === 2) {
                                    this.slice = 500;
                                }
                                this.slice += APP_CONFIG.matchListSlice;
                            });
                        this.routeChange = false;
                    }
                    this.finished(true);
                    if (!this.noCategory()) {
                        // await this.openAtLastOne();
                        this.filterSubtournaments().then(
                            async (): Promise<any> => {
                                // const takeFull =
                                //     this.type === "live"
                                // ? true : this.first;  // TODO  live matches always full
                                const takeFull = this.first;

                                const dataList = await this.matchList.getMatches(
                                    this.sportId,
                                    this.subTournamets,
                                    this.type,
                                    this.from,
                                    this.to,
                                    'alpha',
                                    takeFull,
                                    this.sportName,
                                    this.first,
                                );
                                this.subscriber2 = dataList.subscribe((valList): void => {
                                    if (this.first) {
                                        if (valList.length > 0) {
                                            this.categoryData = _.groupBy(
                                                valList,
                                                (val2): number => val2.sub_tournament_id,
                                            );
                                        }


                                        if (this.first) {
                                            this.openDefault();


                                            this.zone.runOutsideAngular((): void => {
                                                setTimeout((): void => {
                                                    this.zone.run((): void => {
                                                        this.scoreActive = true;
                                                    }, 0);
                                                });
                                            });
                                        }


                                        if (this.io) {
                                            this.io.disconnect();
                                        }

                                        of(true).pipe(delay(1000)).subscribe(() => {
                                            this.boxEl = document.querySelector('#loadingResult');

                                            this.intersectionObserver();
                                        });

                                        this.first = false;
                                    } else {
                                        _.each(valList, (val3): any => {
                                            if (_.isUndefined(this.categoryData[val3.sub_tournament_id])) {
                                                this.categoryData[val3.sub_tournament_id] = [val3];
                                            } else {
                                                const index = this.categoryData[val3.sub_tournament_id]!.findIndex(
                                                    (val2): boolean => val2.id === val3.id,
                                                );
                                                if (index === -1) {
                                                    this.categoryData[val3.sub_tournament_id]!.push(val3);
                                                } else {
                                                    this.categoryData[val3.sub_tournament_id]![index] = val3;
                                                }
                                            }
                                        });
                                    }
                                    $('[data-toggle="tooltip"]').tooltip();
                                });
                            },
                        );
                    } else {
                        if (this.first) {
                            this.openDefault();
                            this.scoreActive = true;
                        }
                        this.first = false;
                    }
                },
                (): void => {
                    if (this.first) {
                        this.error(true);
                    }
                },
            );
        } catch (e) {
            if (this.first) {
                this.error(true);
            }
        }
    }

    /**
     * Load tournaments in open state
     * @return [description]
     */
    private filterSubtournaments(): Promise<void> {
        return new Promise((resolve): void => {
            this.subTournamets = [];
            this.data = _.map(
                this.data,
                (val): CategoryInterface => {
                    if (val.isopen) {
                        this.subTournamets.push(val.sub_tournament_id);
                    }
                    if (_.isUndefined(val.matches)) {
                        val.matches = [];
                    }
                    return val;
                },
            );
            resolve();
        });
    }

    private openAtLastOne(): Promise<void | boolean> {
        return new Promise(
            (resolve): void => {
                // const index = this.data.findIndex((val):
                // boolean => val.isopen);
                if (this.data.length === 0) {
                    resolve(false);
                }
                // if (index === -1) {
                //     const keyOpen = `${this.data[0].category_id}_${
                //         this.data[0].sub_tournament_id
                //     }_${1}`;
                //     this.data[0].isopen = true;
                // }
                resolve();
            },
        );
    }
}

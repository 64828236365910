/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { switchMap, map } from 'rxjs/operators';
import { of, Subscription, timer, Observable, forkJoin } from 'rxjs';
import { chunk, has, uniqBy, head } from 'lodash-es';
import * as $$ from 'jquery';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Form, MetaItem, ParticipantInterface } from '@interfaces/participant.interface';


import { Jersey, MatchData, MatchState } from '@interfaces/match-data.interface';

import { QaType } from '@interfaces/openai.interface';

import { WindowRefService } from '@shared/window-ref';

import $ from '../../../shared/jquery';
import { BaseComponent } from '../../base.component';
import { PageService } from '../../../services/page.service';
import { ParticipantService } from '../../../services/participant.service';
import { UtilsService } from '../../../services/utils.service';
import SPORT_CONFIG from '../../../config/sport.config';
import { environment } from '../../../../environments/environment';
import { ClientService } from '../../../services/client.service';
import { LangService } from '../../../services/lang.service';
import { InfoService } from '../../../services/info.service';
import { MetaService } from '../../../services/meta.service';

const FIRST_MATCH_LIMIT = 40;
const MATCH_LIMIT = 100;

@Component({
    selector: 'app-participant',
    templateUrl: './participant.component.html',
})
export class ParticipantComponent extends BaseComponent implements OnInit, OnDestroy {
    public sportId: number;

    public sportName: string;

    private subscriberReload: Subscription;

    public id: number;

    public data?: ParticipantInterface | any;

    public parentClass: Record<string, boolean> = { 'col-lg-12': false, 'col-lg-8': true };

    public matchClass: Record<string, boolean> = {
        'col-lg-4': true,
        'd-none': true,
        'd-lg-block': true,
    };

    private units: Record<string, string> = { weight: 'kg', height: 'cm' };

    public matchId: number | null = null;

    public tracker: boolean = false;
    public stream: boolean = false;

    public sharedMax: number = 6;

    public finishedLimit: number = SPORT_CONFIG.tournament.roundLimit;

    public notStartedLimit: number = SPORT_CONFIG.tournament.roundLimit;

    public asideLimit: number = SPORT_CONFIG.asideSportDefaultNum;

    public activeTab: number = 1;

    private first: boolean = true;

    public formReverse: any;

    public shareBtnAct: boolean = false;

    public gscTournamentIndex: number;

    public seasonStatsParam: string | null = null;

    public map: boolean = false;

    public activeStats = 1;

    public activeStatsSub: number | null = null;

    public matchState: MatchState;

    public uniqueTournaments: Record<string, number | string>[] = [];

    /**
     * [category name, subtournament name, betradar match id, home | away, season ID, subtournament ID ]
     */
    public participantTableSeason: Set<[string, string, number, 'home' | 'away', number?, number?]>;

    public constructor(
        protected page: PageService,
        private router: ActivatedRoute,
        private participant: ParticipantService,
        protected title: Title,
        protected meta: Meta,
        protected route: Router,
        protected translate: TranslateService,
        public client: ClientService,
        public lang: LangService,
        protected info: InfoService,
        public deviceService: DeviceDetectorService,
        private metaService: MetaService,
        private windowRefService: WindowRefService,
    ) {
        super(page, title, meta, route, info);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.router.paramMap.pipe(switchMap((param): Observable<any> => of(param))).subscribe((params): void => {
            $$('html, body').animate({ scrollTop: 0 }, 0);
            this.reset();
            this.start();
            this.data = undefined;
            this.map = false;
            this.activeTab = 1;
            if (params.has('id')) {
                if (this.subscriberReload instanceof Subscription) {
                    this.subscriberReload.unsubscribe();
                }

                this.id = Number(params.get('id'));

                [this.sportId, this.sportName] = UtilsService.parseSportUrl(params.get('sport'));

                this.first = true;
                this.subscriberReload = timer(0, environment.participantReloadTime)
                    .pipe(switchMap((val): Observable<any> => of(val)))
                    .subscribe((): void => {
                        this.load();
                    });
            } else {
                this.error();
            }
        });

        this.router.fragment.subscribe((fragment): void => {
            this.tab(fragment || '');
        });


        this.router.queryParamMap.subscribe((params: ParamMap) => {
            if (params.has('statsKey')) {
                this.seasonStatsParam = params.get('statsKey') as string;
            }
        });

        if (this.deviceService.isMobile()) {
            this.sharedMax = 2;
        }
        this.gscTournamentIndex = 0;
    }

    public ngOnDestroy(): void {
        if (this.subscriberReload instanceof Subscription) {
            this.subscriberReload.unsubscribe();
        }
    }

    /**
     * Create page title and meta
     * @return Promise<any>
     */
    private createTitle(): void {
        this.translate.get('web.title_participant').subscribe((): void => {
            const { name } = this.data as ParticipantInterface;
            const sport = this.translate.instant(`sport.${this.sportName}`);
            const trans = this.translate.instant('web.title_participant');
            const desc = this.translate.instant('web.description_participant');
            const client = this.client.getVName();
            this.setTitle(
                {
                    title: `${sport} - ${name} ${trans} | ${client}`,
                    description: `${name} - ${sport} ${desc}`,
                },
                true,
            );
        });
    }

    public get isTennis(): boolean {
        return SPORT_CONFIG.isTennis(this.sportName);
    }

    get formFiltered(): Form[] {
        return this.data!.form.filter((f: Record<string, any>) => f.code_state !== 'not_started');
    }

    get metaMain(): MetaItem[][] {
        return chunk(this.data!.meta!.main, 2);
    }

    get hasStats(): boolean {
        return this.hasTotalStats || this.hasGoalsStats || this.hasUOStats ||
            this.hasScoredGoalsStats || this.hasTimelineStats || this.hasTableStandings;
    }


    get hasTotalStats(): boolean {
        return SPORT_CONFIG.statistics.totals.includes(this.sportName);
    }

    get hasTimelineStats(): boolean {
        return SPORT_CONFIG.statistics.timeline.includes(this.sportName);
    }

    get hasGoalsStats(): boolean {
        return SPORT_CONFIG.statistics.goals.includes(this.sportName);
    }

    get hasScoredGoalsStats(): boolean {
        return SPORT_CONFIG.statistics.scoredGoals.includes(this.sportName);
    }

    get hasUOStats(): boolean {
        return SPORT_CONFIG.statistics.uo.includes(this.sportName);
    }

    get hasMatchScoreStats(): boolean {
        return SPORT_CONFIG.statistics.matchScore.includes(this.sportName);
    }

    get aiParticipantType(): QaType {
        return SPORT_CONFIG.displayType.participantPerson.includes(this.sportName)
            ? 'participantPlayer' : 'participantClub';
    }


    get optaSeason(): string | null {
        let stats = this.seasonStatsParam;
        if (!stats && this.data?.season_stats) {
            const key = Object.keys(this.data?.season_stats) ?? [];
            if (key.length > 0) {
                stats = key[0] ?? null;
            }
        }

        return this.data!.season_stats?.[`${stats}`]?.opta_season ?? null;
    }

    get optaTournament(): string | null {
        let stats = this.seasonStatsParam;
        if (!stats && this.data?.season_stats) {
            const key = Object.keys(this.data?.season_stats) ?? [];
            if (key.length > 0) {
                stats = key[0] ?? null;
            }
        }

        return this.data.season_stats?.[`${stats}`]?.opta_competition ?? null;
    }

    get optaTeam(): string | null {
        let stats = this.seasonStatsParam;
        if (!stats && this.data?.season_stats) {
            const key = Object.keys(this.data?.season_stats) ?? [];
            if (key.length > 0) {
                stats = key[0] ?? null;
            }
        }

        return this.data!.season_stats?.[`${stats}`]?.opta_team ?? null;
    }


    get homeJersey(): Jersey | undefined {
        if (!this.data?.jerseys && Array.isArray(this.data!.jerseys)) {
            return undefined;
        }

        return head(this.data!.jerseys!.filter((j: Jersey) => j.jerseyType === 'home'));
    }

    get awayJersey(): Jersey | undefined {
        if (!this.data?.jerseys && Array.isArray(this.data!.jerseys)) {
            return undefined;
        }

        return head(this.data!.jerseys!.filter((j: Jersey) => j.jerseyType === 'away'));
    }

    get goalKeeperJersey(): Jersey | undefined {
        if (!this.data?.jerseys && Array.isArray(this.data!.jerseys)) {
            return undefined;
        }

        return head(this.data!.jerseys!.filter((j: Jersey) => j.jerseyType === 'goalkeeper'));
    }

    get thirdJersey(): Jersey | undefined {
        if (!this.data?.jerseys && Array.isArray(this.data!.jerseys)) {
            return undefined;
        }

        return head(this.data!.jerseys!.filter((j: Jersey) => j.jerseyType === 'third'));
    }


    public jerseyColor(type: 'home' | 'away' | 'goalkeeper' | 'third',
        part: 'base' | 'number' | 'sleeve' | 'shirtType' = 'base'): string {
        // eslint-disable-next-line no-nested-ternary, eqeqeq

        if (type === 'home') {
            const obj = this.homeJersey;
            return obj?.[part] ? `#${obj?.[part]}` : '#fff';
        } if (type === 'away') {
            const obj = this.awayJersey;
            return obj?.[part] ? `#${obj?.[part]}` : '#fff';
        }

        if (type === 'goalkeeper') {
            const obj = this.goalKeeperJersey;
            return obj?.[part] ? `#${obj?.[part]}` : '#fff';
        }

        if (type === 'third') {
            const obj = this.thirdJersey;
            return obj?.[part] ? `#${obj?.[part]}` : '#fff';
        }

        return '#fff';
    }

    public activeTabFce(tab: number): void {
        this.activeTab = tab;

        this.finishedLimit = SPORT_CONFIG.tournament.roundLimitNoRounds;
        this.notStartedLimit = SPORT_CONFIG.tournament.roundLimitNoRounds;

        this.windowRefService.nativeWindow.dataLayer.push({
            event: 'click_tab',
            tab_name: `participant_tab_${tab}`,
            tab_sport: this.sportName,
            tab_team: this.data?.name,
        });
    }

    public get getNearSeason(): number | null {
        return this.data.data.finished && this.data.data.finished.length > 0
            ? this.data.data.finished[0].season_id
            : null;
    }

    // TODO: change after API update https://bumpsro.atlassian.net/browse/LIV-5417
    get hasOptaStats(): boolean {
        return this.client.use('opta') && this.optaTournament !== null &&
        this.optaSeason !== null && this.optaTeam !== null;
    }

    /**
     * Click on shar ebutton icon
     * @param {MouseEvent | null} e
     */
    public shbClick(): void {
        // const valid = e.path.find((val): boolean => {
        //     return (
        //         val.className &&
        //         _.isString(val.className) &&
        //         val.className.includes("fa-share-alt")
        //     );
        // });
        //
        // if (valid !== undefined) {
        //     this.shareBtnAct = !this.shareBtnAct;
        // }

        this.shareBtnAct = !this.shareBtnAct;
    }

    /**
     * Open tab
     * @param {string} tab
     */
    public tab(tab: string): void {
        $(`#participantTab li a[href="#${tab}"]`).tab('show');
        // $$('html, body').animate({ scrollTop: 0 }, 1000);

        if (tab === 'overview') {
            this.activeTabFce(1);
        }

        if (tab === 'results') {
            this.activeTabFce(2);
        }

        if (tab === 'future') {
            this.activeTabFce(3);
        }
        if (tab === 'players') {
            this.activeTabFce(4);
        }
        if (tab === 'news') {
            this.activeTabFce(7);
        }
        if (tab === 'tennis_bilance') {
            this.activeTabFce(8);
        }

        if (tab === 'participantresults') {
            this.activeTabFce(2);
        }
        if (tab === 'participantfuture') {
            this.activeTabFce(3);
        }
    }

    /**
     * Check of page loading is finished
     * @param  {string} type
     * @return  {boolean}
     */
    public isDone(type: string, data: Record<string, any>): boolean {
        if (type === 'finished') {
            if (Number(data.finished.length) <= this.finishedLimit) {
                return true;
            }
        }
        if (type === 'not_started') {
            if (Number(data.not_started.length) <= this.notStartedLimit) {
                return true;
            }
        }

        return false;
    }

    /**
     * Go to next result
     * @param {number} offset
     */
    public nextResult(): void {
        const offset = SPORT_CONFIG.tournament.roundLimitNoRounds;
        this.finishedLimit += offset;
        this.notStartedLimit += offset;
    }

    /**
     * Event fired when some match is selected/clicked
     * @event ParticipantComponent#loadMatch
     * @param  match
     * @return {void}
     */
    public loadMatch(match: any): void {
        if (this.matchId != null && this.matchId === match.id) {
            return;
        }
        // if (this.matchId != null && this.matchId === match.id) {
        //     this.parentClass["col-lg-8"] = false;
        //     this.parentClass["col-lg-12"] = true;
        //     this.matchClass["d-none"] = true;
        //     this.matchClass["d-lg-block"] = false;
        //     this.matchId = null;
        //     return;
        // }
        this.parentClass['col-lg-8'] = true;
        this.parentClass['col-lg-12'] = false;
        // this.matchClass['d-none'] = false;
        this.matchClass['d-lg-block'] = true;
        this.matchState = match.match_state;
        this.matchId = match.id;
        this.tracker = false;
        if (has(match, 'tracker')) {
            this.tracker = match.tracker as boolean;
        }
        if (has(match, 'stream')) {
            this.stream = match.stream as boolean;
        }
    }

    /**
     * Check if aside menu is closed
     * @return {boolean}
     */
    public isAsideDefault(): boolean {
        return this.asideLimit === SPORT_CONFIG.asideSportDefaultNum;
    }

    public onClickStats(stats: number): void {
        if (stats === 2) {
            const request: any[] = [];
            const seasons:[string, string, number, 'home' | 'away', number?, number?][] = [];

            Array.from(this.participantTableSeason)
                .forEach((season:[string, string, number, 'home' | 'away',
                (number | undefined)?, (number | undefined)?]) => {
                    if (season[5]) {
                        request.push(this.participant.getParticipantStandings(season[4], season[5],
                            this.id, this.lang.getLangSnapshot()));
                        seasons.push(season);
                    }
                });

            forkJoin(request).subscribe((resp: any[]) => {
                if (Array.isArray(resp)) {
                    resp.forEach((req: any[], key) => {
                        if (req.length === 0) {
                            this.participantTableSeason
                                .delete(seasons[key] as [string, string, number, 'home' | 'away', number?, number?]);
                        }
                    });
                }
            }, () => {}, () => {
                this.activeStats = stats;
                if (!this.hasTableStandings) {
                    this.activeStats = 1;
                }
            });
        } else {
            this.activeStats = stats;
        }
    }

    get rankingStats(): Record<string, any> | undefined {
        const find = this.data.meta?.statistics?.find(
            (stat: Record<string, any>) => stat.name === SPORT_CONFIG.statistics.participant.meta.ranking);

        return find;
    }

    get hasWinLoseStats(): boolean {
        const find = this.data.meta?.statistics?.find(
            (stat: Record<string, any>) => stat.name === SPORT_CONFIG.statistics.participant.meta.recordWinLose);

        return !!find;
    }

    get participantRecord(): [...number[]] {
        const find = this.data.meta?.statistics?.find(
            (stat: Record<string, any>) => stat.name === SPORT_CONFIG.statistics.participant.meta.recordWinLose);

        if (find) {
            const [win = 0, lose = 0, draw = 0] = find.value.split('-');
            return [win, lose, draw];
        }

        return [0, 0, 0];
    }

    get hasInfo(): boolean {
        return !!(this.data?.players && this.data.players.length > 4 && !this.data.isNationalTeam);
    }

    private calculateTableStandings(): void {
        this.participantTableSeason = new Set();
        const uniqIds: string[] = [];
        this.data.data.finished.forEach((element: MatchData) => {
            if (element.tracker && element.hasTable && element.participants) {
                const key = `${element.category_name!}-${element.sub_tournament_name}`;
                const type = element.participants[0]!.id === this.id ? 'home' : 'away';
                if (!uniqIds.includes(key)) {
                    this.participantTableSeason.add([element.category_name,
                        element.sub_tournament_name, element.tracker, type, element.season_id,
                        element.sub_tournament_id]);
                }
                uniqIds.push(key);
            }
        });

        if (this.participantTableSeason.size > 0) {
            const myArr = Array.from(this.participantTableSeason)[0];
            [,, this.activeStatsSub] = myArr!;
        }
    }

    get calculateTableStandingsPath(): string | null {
        let find = null;


        // eslint-disable-next-line no-restricted-syntax
        for (const item of Array.from(this.participantTableSeason)) {
            if (item[2] === this.activeStatsSub) {
                find = item;
                break;
            }
        }

        if (!find) {
            return null;
        }
        return `${environment.web}/statistics/participant-season-standings-graph/${find[2]}/${find[3]}`;
    }

    get calculateScoresPeriodTotal(): string {
        return `${environment.web}/statistics/participant-period-score?sportName=${this.sportName}&p=${this.id}&type=percentage&score=scored`;
    }

    get calculateScoresPeriodTotalMob(): string {
        return `${environment.web}/statistics/participant-period-score?sportName=${this.sportName}&p=${this.id}&type=percentage&score=scored&width=300&height=350`;
    }


    get calculateConcatedPeriodTotal(): string {
        return `${environment.web}/statistics/participant-period-score?sportName=${this.sportName}&p=${this.id}&type=percentage&score=concated`;
    }

    get calculateConcatedPeriodTotalMob(): string {
        return `${environment.web}/statistics/participant-period-score?sportName=${this.sportName}&p=${this.id}&type=percentage&score=concated&width=300&height=350`;
    }


    get hasTableStandings(): boolean {
        return this.participantTableSeason.size > 0 &&
        SPORT_CONFIG.statistics.participantSeasonStandings.includes(this.sportName);
    }

    /**
     * Toggle aside menu
     * @return {void}
     */
    public toggleAssideLimit(): void {
        this.asideLimit =
            this.asideLimit === SPORT_CONFIG.asideSportDefaultNum ? 12 : SPORT_CONFIG.asideSportDefaultNum;
    }

    /**
     * Open default match on first load
     * @return {void}
     */
    private openDefault(): void {
        if (this.deviceService.isMobile()) {
            return;
        }

        if (this.data.data.not_started.length > 0) {
            this.data.data.not_started[0].tracker = null;
            if (!this.data.season_stats) {
                this.loadMatch(this.data.data.not_started[0]);
            }
        } else if (this.data.data.finished.length > 0) {
            this.data.data.finished[0].tracker = null;
            if (!this.data.season_stats) {
                this.loadMatch(this.data.data.finished[0]);
            }
        }
    }

    protected async load(): Promise<any> {
        try {
            if (this.tabVisible) {
                const Limit = this.first ? FIRST_MATCH_LIMIT : MATCH_LIMIT;

                /*eslint-disable */
                const subscribe = await this.participant.get(this.id, Limit);
                (subscribe as Observable<any>)
                    .pipe(
                        map((val: ParticipantInterface): ParticipantInterface => {
                            if (val.meta?.basic) {
                                val.meta.basic =  val.meta.basic.map(v => {
                                    if (v.name in this.units) {
                                        v.value = `${v.value} ${this.units[v.name]}`;
                                    }
                                    return v;
                                })
                            }
                            return val;
                        })
                    )
                    .subscribe((val): void => {
                        this.data = val;

                        const sportTrans = this.translate.instant(`sport.${this.sportName}`);
                        const { hostname } = this.windowRefService.nativeWindow.location;
                        const jsonLD =     {
                            '@context': 'https://schema.org',
                            '@type': 'BreadcrumbList',
                            itemListElement: [{
                                '@type': 'ListItem',
                                position: 1,
                                name: `${sportTrans}`,
                                item: `https://${hostname}/page/sport/match-list/${this.sportName}-${this.sportId}`,
                            }, {
                                '@type': 'ListItem',
                                position: 2,
                                name: `${this.data.name}`,
                            }],
                        };
                        this.metaService.jsonLDSetter$.next(jsonLD);

                        if (this.first) {
                            this.lang.getLang().then((): any => this.createTitle());
                            this.openDefault();
                            this.formReverse = this.data.form.reverse();
                            this.finished(true);

                            const uniqueTournaments: any[] = uniqBy(this.data.data.finished, 'tournament_id');
                            this.uniqueTournaments = uniqueTournaments.slice(0, 4).map((tournament: MatchData) => {
                                const findTournament = this.data.tournament.find(
                                    (t: MatchData) => t.tournament_id === tournament.tournament_id
                                );

                                if (findTournament) {
                                    return {
                                        name: `${findTournament.sub_tournament_name}`,
                                        id: tournament.tournament_id,
                                    };
                                }

                                return { name: `${tournament.sub_tournament_name}`, id: tournament.tournament_id };
                            });

                            this.calculateTableStandings();
                        }
                        this.first = false;
                    });
                /* eslint-enable */
            }
        } catch (e) {
            if (this.first) {
                this.error();
            }
        }
    }
}

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { Component, OnInit, OnDestroy, AfterViewChecked } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as $$ from 'jquery';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { switchMap } from 'rxjs/operators';
import { merge, Observable, of, Subscription, timer } from 'rxjs';
import { UtilsService } from '@services/utils.service';
import { PlayerService } from '@services/player.service';
import { last, isUndefined, fromPairs, cloneDeep, omitBy, has, isNull } from 'lodash-es';

import { AbstractControl, FormControl, Validators } from '@angular/forms';

import { PlayerInterface, Season } from '@interfaces/player.interface';

import { MatchState, MatchData } from '@interfaces/match-data.interface';

import { STATSGROUP, STATSGROUPSDATA } from '@interfaces/stats.interface';

import { WindowRefService } from '@shared/window-ref';
import { MetaService } from '@services/meta.service';

import { BaseComponent } from '../../base.component';
import { PageService } from '../../../services/page.service';
import { ClientService } from '../../../services/client.service';
import { LangService } from '../../../services/lang.service';
import { InfoService } from '../../../services/info.service';
import { environment } from '../../../../environments/environment';

import $ from '@/shared/jquery';
import SPORT_CONFIG from '@/config/sport.config';

@Component({
    selector: 'app-player',
    templateUrl: './player.component.html',
})
export class PlayerComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewChecked {
    public sportId: number;

    public sportName: string;

    public data: PlayerInterface;

    public dataMatch?:{ all: MatchData[]; live: MatchData[]; finished: MatchData[]; 'not_started': MatchData[] };

    private id: number;

    private subscriber: Subscription;

    private subscriberReload: Subscription;

    public first: boolean;

    public matchId: number | null = null;

    public tracker: boolean = false;
    public stream: boolean = false;

    public matchClass: Record<string, boolean> = {
        'col-lg-4': true,
        'd-none': true,
        'd-lg-block': true,
    };

    public activeTab: number = 1;

    public finishedLimit: number = SPORT_CONFIG.tournament.roundLimit;

    public notStartedLimit: number = SPORT_CONFIG.tournament.roundLimit;

    public seasonControl: AbstractControl;

    public subTournamentControl: AbstractControl;

    public matchState: MatchState;

    public constructor(
        protected page: PageService,
        private router: ActivatedRoute,
        protected title: Title,
        protected meta: Meta,
        protected route: Router,
        protected translate: TranslateService,
        protected client: ClientService,
        protected lang: LangService,
        protected info: InfoService,
        public deviceService: DeviceDetectorService,
        private playerService: PlayerService,
        private metaService: MetaService,
        private windowRefService: WindowRefService,
    ) {
        super(page, title, meta, route, info);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.first = true;
        this.seasonControl = new FormControl('', [Validators.required]);
        this.subTournamentControl = new FormControl(0, [Validators.required]);


        this.router.paramMap.pipe(switchMap((param): Observable<any> => of(param))).subscribe(
            async (params): Promise<any> => {
                $$('html, body').animate({ scrollTop: 0 }, 0);
                this.reset();

                this.subTournamentControl.setValue(0);

                if (params.has('id')) {
                    this.id = Number(params.get('id'));

                    [this.sportId, this.sportName] = UtilsService.parseSportUrl(params.get('sport'));

                    await this.reload();

                    merge(
                        this.seasonControl.valueChanges.pipe(
                            switchMap(
                                (val): Observable<any> => {
                                    if (!this.first) {
                                        this.subTournamentControl.setValue(0);
                                    }

                                    return of(val);
                                },
                            ),
                        ),

                    ).subscribe((): void => {
                        if (this.subscriber instanceof Subscription) {
                            this.subscriber.unsubscribe();
                        }
                        if (this.subscriberReload instanceof Subscription) {
                            this.subscriberReload.unsubscribe();
                        }

                        // if (!this.first) {
                        //     this.normalizeFinished = [];
                        //     this.normalizeNotStarted = [];
                        // }

                        this.first = true;
                        this.reset();
                        // this.activeTabFce(1);
                        this.start();

                        this.load();
                    });

                    if (this.data.seasons && this.data.seasons.length > 0) {
                        const { season_id: seasonId } = last(this.data.seasons) as Season;

                        if (seasonId) {
                            this.seasonControl.patchValue(+seasonId);
                        }
                    }

                    this.router.queryParamMap.subscribe((param) => {
                        if (param.has('season')) {
                            this.seasonControl.patchValue(+param.get('season')!);
                        }
                    });
                } else {
                    this.error();
                }
            });
    }


    public ngOnDestroy(): void {
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }
        if (this.subscriberReload instanceof Subscription) {
            this.subscriberReload.unsubscribe();
        }
        // TODO ?
        // this.tournament.emitLoad(null);
    }

    public ngAfterViewChecked(): void {
        $('[data-toggle="tooltip"]').tooltip();
    }

    public trackByFnSeason(index: number, item: Record<string, any>): number {
        return item.season_id;
    }

    public trackByFnSubtournament(index: number, item: Record<string, any>): number {
        return item.sub_tournament_id;
    }

    public get isMobile(): boolean {
        return this.deviceService.isMobile();
    }

    /**
     * Start reloading data
     * @return {void}
     */
    private reload(): PromiseLike<boolean> {
        if (this.subscriberReload instanceof Subscription) {
            this.subscriberReload.unsubscribe();
        }

        return new Promise((resolve) => {
            this.subscriberReload = timer(0, environment.participantReloadTime)
                .pipe(switchMap((val): Observable<any> => of(val)))
                .subscribe(() => {
                    this.load(null, true, resolve);
                });
        });
    }

    /**
     * Create page title and meta
     * @return Promise<any>
     */
    private createTitle(): any {
        this.translate.get('web.title_player').subscribe((): void => {
            const { name } = this.data;
            const sport = this.translate.instant(`sport.${this.sportName}`);
            const trans = this.translate.instant('web.title_player');
            const desc = this.translate.instant('web.description_player');
            const client = this.client.getVName();
            this.setTitle(
                {
                    title: `${sport} - ${name} ${trans} | ${client}`,
                    description: `${name} - ${sport} ${desc}`,
                },
                true,
            );
        });
    }

    public filterPlayerStats(): (data:[string, number | string][]) => any[] {
        return (data: [string, number | string][]): any[] => {
            if (isUndefined(data) || !data) {
                return [];
            }

            return data.filter((val): boolean => !SPORT_CONFIG.playerStats.exclude.includes(val[0]));
        };
    }

    statsVal(statsData: [string, string][]): Record<string, any> {
        const groups = cloneDeep(STATSGROUPSDATA);
        const allKeys: string[] = [];
        for (const key in groups) {
            const val = Object.keys(groups[key]);
            allKeys.push(...val);
            groups[key] = { ...groups[key],
                ...fromPairs((
                    statsData.filter(v => val.includes(v[0] as any))
                )) };
            groups[key] = omitBy(groups[key], value => !value);
            if (Object.keys(groups[key]).length === 0) {
                delete groups[key];
            }
        }

        const notFound = statsData.filter(v => !allKeys.includes(v[0] as any));

        groups[STATSGROUP.INIT] = { ...groups[STATSGROUP.INIT], ...fromPairs(notFound) };

        return groups;
    }

    /**
     * Load tournament categories
     */
    protected async load(data: any = null, all: boolean = false, resolve?: (...args:any[]) => any): Promise<any> {
        try {
            if (!this.tabVisible || !this.id) {
                if (resolve) {
                    resolve(true);
                    return;
                }
            }
            all = true;
            // const limit = this.first ? MATCH_LIMIT : this.limitMax;
            data = await this.playerService.get(this.id, this.seasonControl.value,
                this.subTournamentControl.value, all);

            this.subscriber = data.subscribe(
                (val: PlayerInterface): any => {
                    this.data = val;

                    const sportTrans = this.translate.instant(`sport.${this.sportName}`);
                    const { hostname } = this.windowRefService.nativeWindow.location;
                    const elements: {'@type': string, position: number, name: string, item?: string}[] = [{
                        '@type': 'ListItem',
                        position: 1,
                        name: `${sportTrans}`,
                        item: `https://${hostname}/page/sport/match-list/${this.sportName}-${this.sportId}`,
                    }];

                    if (this.data.participants && this.data.participants?.length > 0) {
                        elements.push({
                            '@type': 'ListItem',
                            position: 2,
                            name: `${this.data?.participants[0]?.name}`,
                            item: `https://${hostname}/page/sport/participant/${this.sportName}-${this.sportId}/` +
                                `${this.data?.participants[0]?.id}`,
                        });
                        elements.push({
                            '@type': 'ListItem',
                            position: 3,
                            name: `${this.data.name}`,
                        });
                    } else {
                        elements.push({
                            '@type': 'ListItem',
                            position: 2,
                            name: `${this.data.name}`,
                        });
                    }

                    const jsonLD = {
                        '@context': 'https://schema.org',
                        '@type': 'BreadcrumbList',
                        itemListElement: elements,
                    };
                    this.metaService.jsonLDSetter$.next(jsonLD);

                    this.data.stats = val.stats.map((v) => {
                        v.data = Object.entries(v.data);
                        return v;
                    });

                    if (this.first) {
                        if (this.data.subtournaments.length === 1) {
                            this.subTournamentControl.setValue(this.data.subtournaments[0]!.sub_tournament_id);
                        } else if (
                            this.data.last_sub_tournament_id &&
                            !isNull(this.data.last_sub_tournament_id) &&
                            SPORT_CONFIG.displayType.subTournamentUpdate.includes(this.sportName) &&
                            this.subTournamentControl.pristine
                        ) {
                            this.subTournamentControl.setValue(this.data.last_sub_tournament_id);
                        }

                        this.lang.getLang().then((): Promise<any> => this.createTitle());
                    }


                    this.finished(true);
                    if (this.first) {
                        // this.actualSubtournament();
                        // this.openDefault();
                    }
                    this.first = false;
                    // this.tournament.emitLoad(this.data.category_id);

                    if (all) {
                        this.dataMatch = cloneDeep(this.data.data);
                        if (resolve) {
                            resolve(true);
                        }
                    }

                    if (resolve) {
                        resolve(true);
                    }
                },
                (): void => {
                    if (this.first) {
                        this.error();
                    }

                    if (resolve) {
                        resolve(true);
                    }
                },
            );
        } catch (e) {
            if (this.first) {
                this.error();
            }

            if (resolve) {
                resolve(true);
            }
        }
    }

    /**
     * Event fired when some match is selected/clicked
     * @event ParticipantComponent#loadMatch
     * @param  match
     * @return {void}
     */
    public loadMatch(match: any): void {
        if (this.matchId != null && this.matchId === match.id) {
            return;
        }
        // this.parentClass['col-lg-8'] = true;
        // this.parentClass['col-lg-12'] = false;
        // this.matchClass['d-none'] = false;
        // this.matchClass['d-lg-block'] = true;
        this.matchState = match.match_state;
        this.matchId = match.id;
        this.tracker = false;
        if (has(match, 'tracker')) {
            this.tracker = match.tracker as boolean;
        }
        if (has(match, 'stream')) {
            this.stream = match.stream as boolean;
        }
    }

    public activeTabFce(tab: number): void {
        this.activeTab = tab;

        this.windowRefService.nativeWindow.dataLayer.push({
            event: 'click_tab',
            tab_name: `playert_tab_${tab}`,
            tab_sport: this.sportName,
            tab_player: this.data?.name,

        });

        this.finishedLimit = SPORT_CONFIG.tournament.roundLimitNoRounds;
        this.notStartedLimit = SPORT_CONFIG.tournament.roundLimitNoRounds;
    }

    /**
     * Check of page loading is finished
     * @param  {string} type
     * @return  {boolean}
     */
    public isDone(type: string, data: Record<any, any>): boolean {
        if (type === 'finished') {
            if (Number(data.finished.length) <= this.finishedLimit) {
                return true;
            }
        }
        if (type === 'not_started') {
            if (Number(data.not_started.length) <= this.notStartedLimit) {
                return true;
            }
        }

        return false;
    }

    /**
     * Open tab
     * @param {string} tab
     */
    public tab(tab: string): void {
        $(`#participantTab li a[href="#${tab}"]`).tab('show');
        // $$('html, body').animate({ scrollTop: 0 }, 0);

        if (tab === 'playerresults') {
            this.activeTabFce(2);
        }
        if (tab === 'playerfuture') {
            this.activeTabFce(3);
        }
    }
}

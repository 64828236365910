<ng-container *ngIf="isRankingEnable">
    <ng-container *ngIf="$data | async as rank; else error">
        <div class="pt-4">
            <h6 class="match-info-head text-white mb-0 text-uppercase bg-dark p-2">{{ 'web.ranking' | translate }}</h6>
            <ul class="nav nav-tabs" id="tennisRankingTab" role="tablist">
                <li class="nav-item" *ngFor="let nav of rank; first as isFirst">
                    <a
                        class="nav-link"
                        [class.active]="isFirst"
                        id="rankingTab-{{ nav[0] }}"
                        data-toggle="tab"
                        href="#tab-ranking-{{ nav[0] }}"
                        role="tab"
                        aria-controls="info"
                        [attr.ariaSelected]="isFirst ? 'true' : 'false'"
                    >
                        {{ 'web.' + nav[0] | translate }}
                    </a>
                </li>
            </ul>

            <div class="tab-content">
                <div
                    class="tab-pane"
                    *ngFor="let nav of rank; first as isFirst"
                    [class.active]="isFirst"
                    id="tab-ranking-{{ nav[0] }}"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                >
                    <ng-container *ngIf="nav[1].length > 0">
                        <div class="d-flex table-scrollable" style="overflow: auto">
                            <div
                                class="table-scrollable__data flex-grow-1"
                                tableScroll
                                style="overflow: clip; overflow-y: unset !important"
                            >
                                <table class="table-scrollable__data__table table table-tennis-ranking">
                                    <thead>
                                        <tr>
                                            <th scope="col">{{ 'web.rank' | translate }}</th>
                                            <th scope="col">&nbsp;</th>
                                            <th scope="col">{{ 'web.player_name' | translate }}</th>
                                            <!-- <th *ngIf="sportName === 'golf'" scope="col">{{ 'web.prior_rank' | translate }}</th> -->
                                            <th scope="col" class="text-right">{{ pointName | translate }}</th>
                                            <th *ngIf="sportName === 'golf'" scope="col" class="text-right">
                                                {{ 'web.avg_points' | translate }}
                                            </th>
                                            <th *ngIf="sportName === 'golf'" scope="col" class="text-right">
                                                {{ 'web.points_lost' | translate }}
                                            </th>
                                            <th *ngIf="sportName === 'golf'" scope="col" class="text-right">
                                                {{ 'web.points_gained' | translate }}
                                            </th>
                                            <th *ngIf="sportName === 'golf'" scope="col" class="text-right">
                                                {{ 'web.events_played' | translate }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let player of nav[1] | slice : 0 : slice; first as isFirst2"
                                            [class.tennis-ranking-first-row]="isFirst2"
                                        >
                                            <th scope="row" [class.tennis-ranking-first]="isFirst2">
                                                {{ player.ranking }}
                                            </th>
                                            <td class="text-center">
                                                <img
                                                    *ngIf="player.info.image_country !== null && !isFirst2"
                                                    src="{{ player.info.image_country }}"
                                                    alt="{{ player.info.name }}"
                                                    width="16"
                                                    class="mr-2 rounded-circle"
                                                />
                                                <img
                                                    *ngIf="player.info.image !== null && isFirst2"
                                                    src="{{ player.info.image }}"
                                                    alt="{{ player.info.name }}"
                                                    style="width: 60px"
                                                    class="img-fluid rounded-circle"
                                                />
                                            </td>
                                            <td [class.tennis-ranking-first]="isFirst2">
                                                <a
                                                    [routerLink]="[
                                                        '/page/' +
                                                            (sportName === 'golf' ? 'golf' : 'sport') +
                                                            '/participant/' +
                                                            sportName +
                                                            '-' +
                                                            sportId +
                                                            '/' +
                                                            player.participantId
                                                    ]"
                                                    class="text-reset"
                                                    >{{ player.info.name }}</a
                                                >
                                            </td>
                                            <!-- <td *ngIf="sportName === 'golf'">
                                                {{ player.prior_rank }}
                                            </td> -->
                                            <td [class.tennis-ranking-first]="isFirst2" class="text-right">
                                                <ng-container *ngIf="isPrizeMoney">
                                                    {{ player.prizeMoney | number : '1.0-0' }}</ng-container
                                                >
                                                <ng-container *ngIf="isPoints">{{ player.points }} </ng-container>
                                            </td>
                                            <td *ngIf="sportName === 'golf'" class="text-center">
                                                {{ player.avg_points }}
                                            </td>
                                            <td *ngIf="sportName === 'golf'" class="text-center">
                                                {{ player.points_lost }}
                                            </td>
                                            <td *ngIf="sportName === 'golf'" class="text-center">
                                                {{ player.points_gained }}
                                            </td>
                                            <td *ngIf="sportName === 'golf'" class="text-center">
                                                {{ player.events_played }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="mt-1 mb-2 text-center">
                                    <a (click)="slice = 500" class="text-reset app-cursor" *ngIf="slice == 10">{{
                                        'web.show_more' | translate
                                    }}</a>
                                    <a (click)="slice = 10" class="text-reset app-cursor" *ngIf="slice == 500">{{
                                        'web.show_less' | translate
                                    }}</a>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
<ng-template #error></ng-template>

<div class="container bg-light position-relative tournament-page">
    <ng-container *ngIf="loaded && (data || loadedError); else elseBlock">
        <div *ngIf="loadedError" class="pb-6">
            <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                <div class="text-center pt-7">
                    <svg class="icon icon--64 mb-4"><use [attr.xlink:href]="'#icon-' + sportName" /></svg>
                    <h4 class="font-weight-bold">{{ 'web.we_are_sorry' | translate }}</h4>
                    <p class="mb-6">{{ 'web.error_data_load' | translate }}</p>
                </div>
            </div>
        </div>
        <div class="row bg-white p-0" *ngIf="!loadedError">
            <!-- Col Left -->
            <div class="col-lg-8 bg-white pl-0 pr-0" [ngClass]="parentClass">
                <div class="pl-5 pt-5 pr-5 pr-lg-0 pb-3">
                    <!--Progress bar + logo-->
                    <div class="row no-gutters">
                        <div class="col-md-8">
                            <div class="media">
                                <div class="img-team img-team--lg mr-3">
                                    <img
                                        *ngIf="
                                            isMobile && data?.tournament_country_iso && sportName === 'tennis';
                                            else adminIcon
                                        "
                                        src="{{
                                            cdn +
                                                '/img/flags/1x1/' +
                                                (data?.tournament_country_iso | lowercase) +
                                                '.svg'
                                        }}"
                                        width="68"
                                        class="img-fluid mr-2 rounded-circle"
                                        alt="{{ data?.tournament_country_iso }}"
                                    />
                                    <ng-template #adminIcon>
                                        <img
                                            [src]="cdn + data?.image"
                                            alt="{{ data?.tournament_name }}"
                                            width="68"
                                            class="img-fluid mr-2"
                                        />
                                    </ng-template>
                                </div>
                                <div class="media-body">
                                    <div class="d-flex icon-wrapper justify-content-start">
                                        <h1 class="text-uppercase mb-2">{{ data?.tournament_name }}</h1>
                                        <div class="break-md"></div>
                                    </div>
                                    <div class="mb-2 h5 d-flex">
                                        <span class="font-weight-bold mr-1"> {{ subTournamentName }}</span>
                                        <ng-container *ngIf="![170, 497].includes(data?.category_id)"
                                            ><a
                                                [routerLink]="[
                                                    '/page/sport/category',
                                                    sportName + '-' + sportId,
                                                    data?.category_id
                                                ]"
                                                routerLinkActive="text-danger"
                                                data-cy="competiton-navigation-item"
                                                class="text-reset align-self-center"
                                                >({{ data?.category_name }})</a
                                            >
                                        </ng-container>
                                    </div>
                                    <div
                                        class="col-12 col-sm-9 pt-2 pb-2 pt-md-3 pl-md-1 pr-md-3"
                                        *ngIf="showSeasonPeriod"
                                    >
                                        <div class="progress">
                                            <progress
                                                [value]="actualSeasonProgress"
                                                class="livescore-progress-bar"
                                                max="100"
                                            >
                                                {{ actualSeasonProgress }}%
                                            </progress>
                                        </div>
                                        <div class="row mt-2 small" *ngIf="progress">
                                            <div class="col">
                                                {{ progress?.start | livDate : 'localDate' }}
                                            </div>
                                            <div class="col text-right">
                                                {{ progress?.end | livDate : 'localDate' }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Tournament name +  filters-->
                        <div class="col-md-4 text-md-right mt-2 mt-md-auto">
                            <div
                                class="d-flex flex-column-reverse align-items-stretch flex-md-column align-items-end mr-sm-3"
                            >
                                <div class="flex-grow-1" *ngIf="data?.seasons.length > 1">
                                    <select class="custom-select border-light2 mt-1" [formControl]="seasonControl">
                                        <option
                                            *ngFor="let season of reverseSeason; trackBy: trackByFnSeason"
                                            [value]="season.season_id"
                                        >
                                            {{ season.season_name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="flex-grow-1 mt-2" *ngIf="data?.subtournaments.length > 1">
                                    <select class="custom-select border-light2" [formControl]="subTournamentControl">
                                        <option [value]="0">{{ 'web.all_subtournaments' | translate }}</option>
                                        <option
                                            *ngFor="let sub of data?.subtournaments; trackBy: trackByFnSubtournament"
                                            [value]="sub.sub_tournament_id"
                                        >
                                            {{ sub.sub_tournament_name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--End Tournament name +  filters-->
                <div class="nav-wrapper app-mob-nav-wrapper">
                    <!-- <div class="app-mob-nav-shadow"></div> -->
                    <ul
                        class="nav nav-tabs nav-tabs--shadows font-weight-bold pt-3"
                        id="tabs-left"
                        role="tablist"
                        scrollEnd
                    >
                        <li class="nav-item">
                            <a
                                class="nav-link px-3 px-md-4 active"
                                [class.active]="activeTab == 1"
                                id="tab1"
                                data-toggle="tab"
                                href="#overview"
                                [routerLink]="['.']"
                                fragment="overview"
                                role="tab"
                                (click)="activeTabFce(1)"
                                aria-controls="tab1-content"
                                aria-selected="true"
                                >{{ 'web.overview' | translate | transinit }}</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link px-3 px-md-4"
                                [class.active]="activeTab == 2"
                                id="tab2"
                                data-toggle="tab"
                                href="#results"
                                [routerLink]="['./']"
                                fragment="results"
                                role="tab"
                                (click)="activeTabFce(2)"
                                aria-controls="tab2-content"
                                aria-selected="false"
                                >{{ 'web.finished_matches' | renamesportnav : sportName | translate | transinit }}</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link px-3 px-md-4"
                                [class.active]="activeTab == 3"
                                id="tab3"
                                data-toggle="tab"
                                href="#future"
                                [routerLink]="['./']"
                                fragment="future"
                                role="tab"
                                (click)="activeTabFce(3)"
                                aria-controls="tab3-content"
                                aria-selected="false"
                                >{{ 'web.future_matches' | renamesportnav : sportName | translate | transinit }}</a
                            >
                        </li>
                        <li class="nav-item" *ngIf="data?.hasTable">
                            <a
                                class="nav-link px-3 px-md-4"
                                [class.active]="activeTab == 4"
                                id="tab4"
                                data-toggle="tab"
                                href="#table"
                                role="tab"
                                [routerLink]="['./']"
                                fragment="table"
                                (click)="activeTabFce(4)"
                                aria-controls="tab4-content"
                                aria-selected="false"
                                >{{ 'web.league_table' | translate | transinit }}</a
                            >
                        </li>
                        <li class="nav-item" *ngIf="data?.hasCupTree">
                            <a
                                class="nav-link px-3 px-md-4 app-cursor"
                                [class.active]="activeTab == 5"
                                id="tab5"
                                data-toggle="tab"
                                (click)="activeTabFce(5); openCupTree()"
                                role="tab"
                                aria-controls="tab5-content"
                                aria-selected="false"
                                >{{ 'web.cup_tree' | translate | transinit }}</a
                            >
                        </li>
                        <li class="nav-item" *ngIf="data?.hasPlayerStats && selectedSubtournament !== 0">
                            <a
                                class="nav-link px-3 px-md-4"
                                [class.active]="activeTab == 6"
                                id="tab6"
                                data-toggle="tab"
                                href="#player-stats"
                                [routerLink]="['./']"
                                fragment="player-stats"
                                role="tab"
                                (click)="activeTabFce(6)"
                                aria-controls="tab6-content"
                                aria-selected="false"
                                >{{ 'web.player-statistics' | translate | transinit }}</a
                            >
                        </li>
                        <li
                            class="nav-item"
                            *ngIf="data?.players_transfers && (data?.players_transfers)!.length > 0 && displayTransfers"
                        >
                            <a
                                class="nav-link px-3 px-md-4"
                                [class.active]="activeTab == 7"
                                id="tab7"
                                data-toggle="tab"
                                href="#players-transfers"
                                [routerLink]="['./']"
                                fragment="players-transfers"
                                role="tab"
                                (click)="activeTabFce(7)"
                                aria-controls="tab7-content"
                                aria-selected="false"
                                >{{ 'web.players-transfers' | translate | transinit }}</a
                            >
                        </li>
                        <li class="nav-item" *ngIf="data?.season_info && isMobile">
                            <a
                                class="nav-link px-3 px-md-4"
                                [class.active]="activeTab == 8"
                                id="tab8"
                                data-toggle="tab"
                                href="#tennis-tournament-info"
                                [routerLink]="['./']"
                                fragment="tennis-tournament-info"
                                role="tab"
                                (click)="activeTabFce(8)"
                                aria-controls="tab8-content"
                                aria-selected="false"
                                >{{ 'web.tennis_tournament_info' | translate | transinit }}</a
                            >
                        </li>
                    </ul>
                </div>
                <div class="tab-content bg-white px-0 px-lg-3 py-3 position-relative">
                    <div
                        class="tab-pane active"
                        [class.active]="activeTab == 1"
                        *ngIf="activeTab == 1"
                        id="overview"
                        role="tabpanel"
                        aria-labelledby="tab1"
                    >
                        <ng-container *ngIf="isMobile && hasOptaStats">
                            <app-auth-container [authOff]="!client.use('signin')" permission="stats">
                                <app-opta-competition-stats
                                    [competitionId]="data?.opta_competition"
                                    [seasonId]="data?.opta_season"
                                ></app-opta-competition-stats>
                                <app-opta-team-ranking
                                    [competitionId]="data?.opta_competition"
                                    [seasonId]="data?.opta_season"
                                ></app-opta-team-ranking>
                            </app-auth-container>
                        </ng-container>
                        <!--Live matches-->
                        <ng-container *ngIf="data?.data?.live && data?.data?.live.length > 0">
                            <h6
                                class="table-sport__head bg-dark app-text-dark-tournament bg-primary--light"
                                data-toggle="collapse"
                                data-target="#collapse-live"
                                role="button"
                                aria-expanded="true"
                                aria-controls="collapse-today"
                            >
                                <span class="table-sport__head__text app-text-dark-tournament">{{
                                    'web.live_matches' | translate | transinit
                                }}</span>
                            </h6>
                            <div class="collapse show" id="collapse-live">
                                <app-component-match
                                    *ngFor="let match of data?.data?.live; trackBy: trackById"
                                    [match]="match"
                                    [sportName]="sportName"
                                    [sportId]="sportId"
                                    page="tournament"
                                    (onSelect)="loadMatch($event)"
                                ></app-component-match>
                            </div>
                        </ng-container>
                        <!--End Live matches-->
                        <!--League latest matches-->
                        <ng-container
                            *ngIf="
                                (hasNoRounds || allMode || data?.hasTable || (!data?.hasTable && !data?.hasCupTree)) &&
                                this.data?.data?.finished.length > 0
                            "
                        >
                            <h6
                                class="table-sport__head bg-dark text-white mt-3"
                                data-toggle="collapse"
                                data-target="#collapse-last"
                                role="button"
                                aria-expanded="true"
                                aria-controls="collapse-last"
                            >
                                <span class="table-sport__head__text">{{
                                    'web.latest_matches' | translate | transinit
                                }}</span>
                            </h6>
                            <ng-container *ngIf="allMode || this.isNoRoundF">
                                <div class="collapse show" id="collapse-last">
                                    <ng-container
                                        *ngFor="
                                            let match of this.data?.data?.finished | slice : 0 : 20;
                                            index as i;
                                            trackBy: trackById
                                        "
                                    >
                                        <div
                                            *ngIf="
                                                allMode &&
                                                (i === 0 ||
                                                    match.sub_tournament_name !==
                                                        this.data?.data?.finished[i - 1].sub_tournament_name)
                                            "
                                            class="table-sport__subhead d-flex align-items-center bg-light ng-star-inserted"
                                        >
                                            <div class="mb-0 text-uppercase text-truncate">
                                                {{ match.sub_tournament_name }}
                                            </div>
                                        </div>
                                        <app-component-match
                                            [dateFormat]="'localDateTimeShort'"
                                            dateFormat2="localDateShort"
                                            [match]="match"
                                            [sportName]="sportName"
                                            [sportId]="sportId"
                                            page="tournament"
                                            (onSelect)="loadMatch($event)"
                                        ></app-component-match>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!allMode && !this.isNoRoundF">
                                <div class="collapse show" id="collapse-last">
                                    <ng-container
                                        *ngFor="
                                            let matches of normalizeFinishedByDate;
                                            trackBy: trackByIndex;
                                            index as i
                                        "
                                    >
                                        <div
                                            class="table-sport__subhead d-flex align-items-center bg-light"
                                            data-toggle="collapse"
                                            [attr.data-target]="'#collapse-last-' + i"
                                            role="button"
                                            aria-expanded="true"
                                            aria-controls="collapse-last-1"
                                        >
                                            <div class="table-sport__subhead__text mb-0 text-uppercase text-truncate">
                                                <ng-container *ngIf="matches[0]?.round_name != null">{{
                                                    matches[0]?.round_name
                                                }}</ng-container>
                                                <ng-container
                                                    *ngIf="matches[0]?.round_name == null && matches[0]?.round != null"
                                                    >{{ matches[0]?.round }}.
                                                    {{ 'web.round' | translate | transinit }}</ng-container
                                                >
                                            </div>
                                        </div>
                                        <div class="collapse show" id="collapse-last-{{ i }}">
                                            <app-component-match
                                                *ngFor="let match of matches; trackBy: trackById"
                                                [dateFormat]="'localDateTimeShort'"
                                                dateFormat2="localDateShort"
                                                [match]="match"
                                                [sportName]="sportName"
                                                [sportId]="sportId"
                                                page="tournament"
                                                (onSelect)="loadMatch($event)"
                                            ></app-component-match>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <div class="text-center mt-3 mb-4">
                                <button class="btn btn-outline-secondary btn-sm px-5" (click)="tab('results')">
                                    {{ 'web.more_results' | translate }}
                                </button>
                            </div>
                        </ng-container>
                        <!--End League latest matches-->
                        <!--League Not started matches-->
                        <ng-container
                            *ngIf="
                                (hasNoRounds || allMode || data?.hasTable || (!data?.hasTable && !data?.hasCupTree)) &&
                                this.data?.data?.not_started.length > 0
                            "
                        >
                            <h6
                                class="table-sport__head bg-dark text-white mt-3"
                                data-toggle="collapse"
                                data-target="#collapse-not"
                                role="button"
                                aria-expanded="true"
                                aria-controls="collapse-last"
                            >
                                <span class="table-sport__head__text">{{
                                    'web.not_started_matches' | translate | transinit
                                }}</span>
                            </h6>
                            <ng-container *ngIf="allMode || this.isNoRoundNS">
                                <div class="collapse show" id="collapse-not">
                                    <ng-container
                                        *ngFor="
                                            let match of this.data?.not_started_r | slice : 0 : 20;
                                            trackBy: trackById
                                        "
                                    >
                                        <div
                                            *ngIf="allMode"
                                            class="table-sport__subhead d-flex align-items-center bg-light ng-star-inserted"
                                        >
                                            <div class="mb-0 text-uppercase text-truncate">
                                                {{ match.sub_tournament_name }}
                                            </div>
                                        </div>
                                        <app-component-match
                                            [dateFormat]="'localDateTimeShort'"
                                            [match]="match"
                                            [sportName]="sportName"
                                            [sportId]="sportId"
                                            page="tournament"
                                            (onSelect)="loadMatch($event)"
                                        ></app-component-match>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!allMode && !this.isNoRoundNS">
                                <div class="collapse show" id="collapse-not">
                                    <ng-container
                                        *ngFor="
                                            let matches of normalizeNotStartedByDate;
                                            trackBy: trackByIndex;
                                            index as i
                                        "
                                    >
                                        <div
                                            class="table-sport__subhead d-flex align-items-center bg-light"
                                            data-toggle="collapse"
                                            [attr.data-target]="'#collapse-not-' + i"
                                            role="button"
                                            aria-expanded="true"
                                            aria-controls="collapse-last-1"
                                        >
                                            <div class="table-sport__subhead__text mb-0 text-uppercase text-truncate">
                                                <ng-container *ngIf="matches[0]?.round_name != null">{{
                                                    matches[0]?.round_name
                                                }}</ng-container>
                                                <ng-container
                                                    *ngIf="matches[0]?.round_name == null && matches[0]?.round != null"
                                                    >{{ matches[0]?.round }}.
                                                    {{ 'web.round' | translate | transinit }}</ng-container
                                                >
                                            </div>
                                        </div>
                                        <div class="collapse show" id="collapse-not-{{ i }}">
                                            <app-component-match
                                                *ngFor="let match of matches; trackBy: trackById"
                                                [dateFormat]="'localDateTimeShort'"
                                                [match]="match"
                                                [sportName]="sportName"
                                                [sportId]="sportId"
                                                page="tournament"
                                                (onSelect)="loadMatch($event)"
                                            ></app-component-match>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <div class="text-center mt-3 mb-4">
                                <button class="btn btn-outline-secondary btn-sm px-5" (click)="tab('future')">
                                    {{ 'web.more_future' | translate }}
                                </button>
                            </div>
                        </ng-container>
                        <!--End League Not started matches-->

                        <!-- Cup tree data-->

                        <!--Cup tree latest matches-->
                        <ng-container *ngIf="!allMode && !hasNoRounds && data?.hasCupTree && !data?.hasTable">
                            <ng-container *ngFor="let matches of cupTreeOverview; trackBy: trackByIndex; index as i">
                                <ng-container *ngIf="matches[0]?.round_name != null || matches[0]?.round != null">
                                    <h6
                                        class="table-sport__head bg-dark text-white mt-3"
                                        data-toggle="collapse"
                                        [attr.data-target]="'#collapse-cuptree-' + i"
                                        role="button"
                                        aria-expanded="true"
                                        aria-controls="collapse-last"
                                    >
                                        <span class="table-sport__head__text" *ngIf="matches[0]?.round_name != null">{{
                                            matches[0]?.round_name
                                        }}</span>
                                        <span
                                            class="table-sport__head__text"
                                            *ngIf="matches[0]?.round_name == null && matches[0]?.round != null"
                                            >{{ matches[0]?.round }}. {{ 'web.round' | translate | transinit }}</span
                                        >
                                    </h6>
                                    <div class="collapse show" id="collapse-cuptree-{{ i }}">
                                        <app-component-match
                                            *ngFor="let match of matches; trackBy: trackById"
                                            [dateFormat]="'localDateTimeShort'"
                                            dateFormat2="localDateShort"
                                            [match]="match"
                                            [sportName]="sportName"
                                            [sportId]="sportId"
                                            page="tournament"
                                            (onSelect)="loadMatch($event)"
                                        ></app-component-match>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <div class="text-center mt-3 mb-4">
                                <button class="btn btn-outline-secondary btn-sm px-5" (click)="tab('results')">
                                    {{ 'web.more_results' | translate }}
                                </button>
                            </div>
                        </ng-container>
                        <!--Cup tree latest matches-->
                    </div>
                    <div
                        class="tab-pane"
                        [class.active]="activeTab == 2"
                        *ngIf="activeTab == 2"
                        id="results"
                        role="tabpanel"
                        aria-labelledby="tab1"
                    >
                        <!--Finished matches-->
                        <ng-container *ngIf="allMode || this.isNoRoundF">
                            <app-component-match
                                *ngFor="
                                    let match of this.data?.data?.finished | slice : 0 : finishedLimit;
                                    trackBy: trackById
                                "
                                [dateFormat]="'localDateTimeShort'"
                                dateFormat2="localDateShort"
                                [match]="match"
                                [sportName]="sportName"
                                [sportId]="sportId"
                                page="tournament"
                                (onSelect)="loadMatch($event)"
                            ></app-component-match>
                        </ng-container>
                        <ng-container *ngIf="!allMode && !this.isNoRoundF">
                            <ng-container
                                *ngFor="
                                    let matches of normalizeFinished | slice : 0 : finishedLimit;
                                    trackBy: trackByIndex;
                                    index as i
                                "
                            >
                                <ng-container *ngIf="matches[0]?.round_name != null || matches[0]?.round != null">
                                    <h6
                                        class="table-sport__head bg-dark text-white mt-3"
                                        data-toggle="collapse"
                                        [attr.data-target]="'#collapse-finished-sub-' + i"
                                        role="button"
                                        aria-expanded="true"
                                        aria-controls="collapse-last"
                                    >
                                        <span class="table-sport__head__text" *ngIf="matches[0]?.round_name != null">{{
                                            matches[0]?.round_name
                                        }}</span>
                                        <span
                                            class="table-sport__head__text"
                                            *ngIf="matches[0]?.round_name == null && matches[0]?.round != null"
                                            >{{ matches[0]?.round }}. {{ 'web.round' | translate | transinit }}</span
                                        >
                                    </h6>
                                    <div class="collapse show" id="collapse-finished-sub-{{ i }}">
                                        <app-component-match
                                            *ngFor="let match of matches; trackBy: trackById"
                                            [dateFormat]="'localDateTimeShort'"
                                            dateFormat2="localDateShort"
                                            [match]="match"
                                            [sportName]="sportName"
                                            [sportId]="sportId"
                                            page="tournament"
                                            (onSelect)="loadMatch($event)"
                                        ></app-component-match>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>

                        <div class="text-center mt-3 mb-4" *ngIf="!isDone('finished')">
                            <button class="btn btn-outline-secondary btn-sm px-5" (click)="nextResult()">
                                {{ 'web.next_result' | translate }}
                            </button>
                        </div>

                        <div *ngIf="this.data?.data?.finished.length == 0" class="text-center">
                            <strong>{{ 'web.no_data_found' | translate }}</strong>
                        </div>
                        <!--End Finished matches-->
                    </div>
                    <div
                        class="tab-pane"
                        [class.active]="activeTab == 3"
                        *ngIf="activeTab == 3"
                        id="future"
                        role="tabpanel"
                        aria-labelledby="tab1"
                    >
                        <!--Future matches-->
                        <ng-container *ngIf="allMode || this.isNoRoundNS">
                            <app-component-match
                                *ngFor="
                                    let match of this.data?.not_started_r | slice : 0 : notStartedLimit;
                                    trackBy: trackById
                                "
                                [dateFormat]="'localDateTimeShort'"
                                [match]="match"
                                [sportName]="sportName"
                                [sportId]="sportId"
                                page="tournament"
                                (onSelect)="loadMatch($event)"
                            ></app-component-match>
                        </ng-container>
                        <ng-container *ngIf="!allMode && !this.isNoRoundNS">
                            <ng-container
                                *ngFor="
                                    let matches of normalizeNotStarted | slice : 0 : notStartedLimit;
                                    trackBy: trackByIndex;
                                    index as i
                                "
                            >
                                <ng-container *ngIf="matches[0]?.round_name != null || matches[0]?.round != null">
                                    <h6
                                        class="table-sport__head bg-dark text-white mt-3"
                                        data-toggle="collapse"
                                        [attr.data-target]="'#collapse-not_started-sub-' + i"
                                        role="button"
                                        aria-expanded="true"
                                        aria-controls="collapse-last"
                                    >
                                        <span class="table-sport__head__text" *ngIf="matches[0]?.round_name != null">{{
                                            matches[0]?.round_name
                                        }}</span>
                                        <span
                                            class="table-sport__head__text"
                                            *ngIf="matches[0]?.round_name == null && matches[0]?.round != null"
                                            >{{ matches[0]?.round }}. {{ 'web.round' | translate | transinit }}</span
                                        >
                                    </h6>
                                    <div class="collapse show" id="collapse-not_started-sub-{{ i }}">
                                        <app-component-match
                                            *ngFor="let match of matches; trackBy: trackById"
                                            [dateFormat]="'localDateTimeShort'"
                                            [match]="match"
                                            [sportName]="sportName"
                                            [sportId]="sportId"
                                            page="tournament"
                                            (onSelect)="loadMatch($event)"
                                        ></app-component-match>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <div class="text-center mt-3 mb-4" *ngIf="!isDone('not_started')">
                            <button class="btn btn-outline-secondary btn-sm px-5" (click)="nextResult()">
                                {{ 'web.next_result' | translate }}
                            </button>
                        </div>

                        <div *ngIf="this.data?.data?.not_started.length == 0" class="text-center">
                            <strong>{{ 'web.no_data_found' | translate }}</strong>
                        </div>
                        <!--End Future matches-->
                    </div>
                    <div
                        class="tab-pane"
                        [class.active]="activeTab == 4"
                        *ngIf="activeTab == 4"
                        id="table"
                        role="tabpanel"
                        aria-labelledby="tab1"
                    >
                        <app-league-table
                            [sportName]="sportName"
                            [sportId]="sportId"
                            [subTournamentId]="data?.sub_tournament_id"
                            [seasonId]="data?.season_id"
                        ></app-league-table>
                    </div>
                    <div
                        class="tab-pane"
                        [class.active]="activeTab == 6"
                        *ngIf="activeTab == 6"
                        id="player-stats"
                        role="tabpanel"
                        aria-labelledby="tab1"
                    >
                        <app-player-stats
                            [subTournamentId]="data?.sub_tournament_id"
                            [seasonId]="data?.season_id"
                            [sportName]="sportName"
                            [sportId]="sportId"
                        ></app-player-stats>
                    </div>
                    <div
                        class="tab-pane"
                        [class.active]="activeTab == 7"
                        *ngIf="activeTab == 7"
                        id="players-transfers"
                        role="tabpanel"
                        aria-labelledby="tab7"
                    >
                        <app-player-transfers
                            [playerTransfers]="data?.players_transfers"
                            [sportId]="sportId"
                            [sportName]="sportName"
                        ></app-player-transfers>
                    </div>
                    <div
                        class="tab-pane"
                        [class.active]="activeTab == 8"
                        *ngIf="activeTab == 8 && isMobile"
                        id="tennis-tournament-info"
                        role="tabpanel"
                        aria-labelledby="tab8"
                    >
                        <app-tennis-tournament-info
                            *ngIf="data?.season_info"
                            [tennisTournamentInfo]="data?.season_info"
                            [iso]="data?.tournament_country_iso"
                            [sportId]="sportId"
                            [sportName]="sportName"
                        ></app-tennis-tournament-info>
                    </div>

                    <div *ngIf="client.use('seoCard') && data?.tournament_info" class="card shadow border-0 mt-5">
                        <div class="app-info-card card-body p-0 p-md-5">
                            <div class="media">
                                <svg class="icon icon--32 mr-3">
                                    <use href="#icon-bulp-2" />
                                </svg>
                                <div class="media-body mt-2">
                                    <h6 class="font-weight-bold text-uppercase">{{ data?.tournament_name }}</h6>
                                    <div [innerHTML]="data?.tournament_info | markdown"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- / Col Left -->
            <!-- Col Right -->
            <div class="col-lg-4 d-none d-lg-block" [ngClass]="matchClass">
                <app-tennis-tournament-info
                    *ngIf="data?.season_info"
                    [tennisTournamentInfo]="data?.season_info"
                    [iso]="data?.tournament_country_iso"
                    [sportId]="sportId"
                    [sportName]="sportName"
                ></app-tennis-tournament-info>

                <ng-container *ngIf="hasOptaStats">
                    <app-auth-container [authOff]="!client.use('signin')" permission="stats">
                        <app-opta-competition-stats
                            [competitionId]="data?.opta_competition"
                            [seasonId]="data?.opta_season"
                        ></app-opta-competition-stats>
                        <app-opta-team-ranking
                            [competitionId]="data?.opta_competition"
                            [seasonId]="data?.opta_season"
                        ></app-opta-team-ranking>
                    </app-auth-container>
                </ng-container>
                <app-switchers (onStickyChange)="onStickyChange($event)"></app-switchers>
                <div
                    *ngIf="matchId"
                    [class.sticky-parent]="stickyDetail"
                    class="col-right__inner bg-white"
                    data-cy="right-column-match"
                >
                    <app-match-detail
                        class="sticky-parent__match"
                        [class.app-match-detail--shadow]="stickyDetail"
                        [style.max-height]="viewPortHeightMatchDetail"
                        [id]="matchId"
                        [matchState]="matchState"
                        [tracker]="tracker"
                        [stream]="stream"
                    ></app-match-detail>
                </div>

                <app-tournament-link
                    *ngIf="tournamentLink"
                    [sportId]="sportId"
                    [categoryId]="data?.category_id"
                    [tournamentId]="data?.tournament_id"
                    [flag]="data?.image"
                    [name]="data?.category_name"
                ></app-tournament-link>

                <app-tennis-ranking [sportName]="sportName"></app-tennis-ranking>
            </div>
            <!-- / Col Right -->
        </div>

        <aside class="nav-sports d-none d-xl-block" *ngIf="client.use('sportQuickNav')">
            <nav class="nav flex-column bg-light">
                <app-sport-navigation
                    type="aside"
                    [date]="paramDate"
                    [limit]="asideLimit"
                    [sportId]="sportId"
                ></app-sport-navigation>
                <a
                    class="nav-link nav-link--more text-uppercase text-reset"
                    [class.nav-link--more]="isAsideDefault()"
                    [class.nav-link--less]="!isAsideDefault()"
                    (click)="toggleAssideLimit()"
                >
                    <span *ngIf="isAsideDefault()">{{ 'web.more' | translate }}</span>
                    <span *ngIf="!isAsideDefault()">{{ 'web.less' | translate }}</span>
                </a>
            </nav>
        </aside>
        <aside [class.d-xl-block]="client.use('sportQuickNav')" class="nav-sports nav-sports--extra d-none">
            <nav class="nav flex-column nav-side-menu bg-light">
                <app-side-menu-navigation [sportId]="sportId" [sportName]="sportName"></app-side-menu-navigation>
            </nav>
        </aside>
    </ng-container>
    <ng-template #elseBlock>
        <div class="row">
            <!-- Col Left -->
            <div class="col-lg-8 bg-white pl-0 pr-0" [ngClass]="parentClass">
                <app-placeholder-tournament></app-placeholder-tournament>
            </div>

            <div class="col-lg-4 p-3 d-none d-lg-block" [ngClass]="matchClass">
                <app-placeholder-detail></app-placeholder-detail>
            </div>
        </div>
    </ng-template>
</div>

import { Component, OnChanges, Input } from '@angular/core';
import { map } from 'rxjs/operators';
import SPORT_CONFIG from 'src/app/config/sport.config';
import { SportService } from 'src/app/services/sport.service';
import { TennisRankingInterface } from 'src/app/interfaces/tennis-rankings.interface';
import * as _ from 'underscore';
import { Subscription } from 'rxjs';

import { BaseComponent } from '../../base.component';

@Component({
    selector: 'app-tennis-ranking',
    templateUrl: './tennis-ranking.component.html',
})
export class TennisRankingComponent extends BaseComponent
    implements OnChanges {
    @Input()
    public sportName: string;

    @Input()
    public sportId: number = 10;

    /**
     * What range of data get
     * - all: get wta and all
     * - wta
     * - atp
     */
    @Input()
    private type: string = 'all';

    public wta: Subscription | null;

    public atp: Subscription | null;

    public atpslice: number = 10;

    public wtaslice: number = 10;

    public constructor(public sport: SportService) {
        super();
    }


    public ngOnChanges(): void {
        this.start();
        this.atp = null;
        this.wta = null;
        if (this.isTennis) {
            if (this.type === 'all' || this.type === 'wta') { this.load('wta'); }
            if (this.type === 'all' || this.type === 'atp') { this.load('atp'); }
        }
    }

    public get isTennis(): boolean {
        return SPORT_CONFIG.isTennis(this.sportName);
    }

    /**
     * Get tab keys
     * @param  {object} tab
     * @return {string[]}
     */
    public keys(tab: Record<string, any>): string[] {
        return _.keys(tab);
    }

    /**
     * Load data
     */
    private async load(type: string): Promise<any> {
        try {
            const helper = await this.sport.getTennisRankings(type);

            // @ts-ignore
            this[type] = helper.pipe(
                map((val): TennisRankingInterface[] => _.filter(val, (v): boolean => v.category === type),
                ),
                map((val): { [prop: string]: TennisRankingInterface[] } => {
                    const grouped = _.groupBy(val, 'sd');
                    return { asingle: grouped.single,
                        double: grouped.double,
                        singlerace: grouped.single_race,
                        xdoublerace: grouped.double_race };
                }),
            );
            this.finished();
        } catch (e) {
            this.error();
        }
    }
}

<div class="container bg-light position-relative">
    <div class="row bg-white p-0">
        <div class="col-lg-8 bg-white p-0" [ngClass]="parentClass">
            <div class="nav-wrapper app-mob-nav-wrapper">
                <ul
                    class="nav nav-tabs nav-tabs--shadows nav-justified--mobile font-weight-bold"
                    id="tabs-left"
                    aria-label="All, live, upcoming and finished matches tabs"
                    role="tablist"
                >
                    <li
                        class="nav-item"
                        role="tab"
                        [attr.aria-controls]="'tab' + matchTab.ALL + '-content'"
                        [attr.aria-selected]="type === matchTab.ALL"
                        [attr.tabindex]="type === matchTab.ALL ? 0 : -1"
                        [attr.id]="'tab' + matchTab.ALL"
                    >
                        <a
                            class="nav-link px-3 px-md-4 active"
                            (click)="changeTab(matchTab.ALL)"
                            [class.active]="type == 'all'"
                            data-toggle="tab"
                            href="#tab1-content"
                            >{{ 'web.all_matches' | translate | transinit }}
                        </a>
                    </li>
                    <li
                        class="nav-item tab-odds"
                        [class.d-none]="!(isMobile && client.use('oddsTab') && partner.getCount() > 0 && !isInPast)"
                        role="tab"
                        [attr.aria-controls]="'tab' + matchTab.ODDS + '-content'"
                        [attr.aria-selected]="type === matchTab.ODDS"
                        [attr.tabindex]="type === matchTab.ODDS ? 0 : -1"
                        [attr.id]="'tab' + matchTab.ODDS"
                    >
                        <a
                            class="nav-link px-3 px-md-4"
                            (click)="changeTab(matchTab.ODDS)"
                            [class.active]="type == 'odds'"
                            data-toggle="tab"
                            href="#tab5-content"
                            >{{ 'web.tab_odds' | translate | transinit }}</a
                        >
                    </li>
                    <li
                        class="nav-item"
                        role="tab"
                        [attr.aria-controls]="'tab' + matchTab.LIVE + '-content'"
                        [attr.aria-selected]="type === matchTab.LIVE"
                        [attr.tabindex]="type === matchTab.LIVE ? 0 : -1"
                        [attr.id]="'tab' + matchTab.LIVE"
                    >
                        <a
                            class="nav-link px-3 px-md-4 app-live-tab"
                            (click)="changeTab(matchTab.LIVE)"
                            [class.active]="type == 'live'"
                            data-toggle="tab"
                            href="#tab2-content"
                            >{{ 'web.live_matches' | translate | transinit }}</a
                        >
                    </li>
                    <li
                        class="nav-item"
                        role="tab"
                        [attr.aria-controls]="'tab' + matchTab.FINISHED + '-content'"
                        [attr.aria-selected]="type === matchTab.FINISHED"
                        [attr.tabindex]="type === matchTab.FINISHED ? 0 : -1"
                        [attr.id]="'tab' + matchTab.FINISHED"
                    >
                        <a
                            class="nav-link px-3 px-md-4"
                            (click)="changeTab(matchTab.FINISHED)"
                            [class.active]="type == 'finished'"
                            data-toggle="tab"
                            href="#tab3-content"
                            data-cy="tab-finished"
                            >{{ 'web.finished_matches' | renamesportnav : sportName | translate | transinit }}</a
                        >
                    </li>
                    <li
                        class="nav-item"
                        role="tab"
                        [attr.aria-controls]="'tab' + matchTab.NOTSTARTED + '-content'"
                        [attr.aria-selected]="type === matchTab.NOTSTARTED"
                        [attr.tabindex]="type === matchTab.NOTSTARTED ? 0 : -1"
                        [attr.id]="'tab' + matchTab.NOTSTARTED"
                    >
                        <a
                            class="nav-link px-3 px-md-4"
                            (click)="changeTab(matchTab.NOTSTARTED)"
                            [class.active]="type == 'not_started'"
                            data-toggle="tab"
                            data-cy="tab-not-started"
                            href="#tab4-content"
                            >{{ 'web.future_matches' | renamesportnav : sportName | translate | transinit }}</a
                        >
                    </li>
                    <li
                        *ngIf="isTennis || hasRanking"
                        class="nav-item"
                        role="tab"
                        [attr.aria-controls]="'tabtabranking-content'"
                        [attr.aria-selected]="type === matchTab.RANKING"
                        [attr.tabindex]="type === matchTab.RANKING ? 0 : -1"
                        id="tabranking"
                    >
                        <a
                            class="nav-link px-3 px-md-4 app-ranking-tab"
                            id="app-ranking-tab"
                            (click)="changeTab(matchTab.RANKING)"
                            [class.active]="type == matchTab.RANKING"
                            data-toggle="tab"
                            data-cy="tab-not-started"
                            href="#tab6-content"
                            >{{ 'web.ranking' | translate | transinit }}</a
                        >
                    </li>
                    <li
                        class="nav-item ml-auto bg-white align-items-center px-2 d-none d-md-flex app-mob-calendar"
                        [class.w-100]="isMobile"
                        *ngIf="!isMobile"
                        role="tab"
                    >
                        <app-calendar class="d-flex align-items-center" (onChange)="dateChange($event)"></app-calendar>
                    </li>
                </ul>
            </div>
            <div class="d-md-none dropdown-calendar">
                <app-calendar [mobile]="true" (onChange)="dateChange($event)"></app-calendar>
            </div>
            <div class="tab-content bg-white p-3 has-nomenclature position-relative">
                <div
                    class="tab-pane active"
                    [attr.id]="'tab' + type + '-content'"
                    role="tabpanel"
                    [attr.aria-labelledby]="'tab' + type"
                >
                    <div class="table-sport mx-n3 mx-sm-0" *ngIf="type === 'ranking'">
                        <app-tennis-ranking [sportName]="sportName" [sportId]="sportId"></app-tennis-ranking>
                        <app-ranking [sportName]="sportName" [sportId]="sportId"></app-ranking>
                    </div>
                    <div class="table-sport mx-n3 mx-sm-0" *ngIf="type !== 'ranking'">
                        <div *ngIf="loaded; else elseBlock" class="table-sport__content">
                            <ng-container *ngIf="loadedError; else loadBlock">
                                <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                                    <div class="text-center pt-7">
                                        <svg class="icon icon--64 mb-4">
                                            <use [attr.xlink:href]="'#icon-' + sportName" />
                                        </svg>
                                        <h4 class="font-weight-bold">{{ 'web.we_are_sorry' | translate }}</h4>
                                        <p class="mb-6">{{ 'web.error_data_load' | translate }}</p>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #loadBlock>
                                <ng-container *ngIf="!noCategory()">
                                    <ng-container *ngIf="isGroupCategory; else normalGroup">
                                        <app-category-group
                                            *ngFor="let group of categoryGroup; trackBy: trackByIndex; index as i"
                                            [group]="group"
                                            (groupChange)="categoryGroup[i] = $event"
                                        >
                                            <ng-container *ngIf="group?.isopen && !loadedError">
                                                <app-component-category
                                                    *ngFor="
                                                        let cg of categoryGroupData[group.category_id]
                                                            | navSearch : 'alpha' : alphaFilter : sportId : sportName
                                                            | slice : 0 : slice;
                                                        trackBy: trackByFn
                                                    "
                                                    [cg]="cg"
                                                    [showCategoryName]="false"
                                                    [sportId]="sportId"
                                                    [sportName]="sportName"
                                                    (cgChange)="load(true)"
                                                >
                                                    <ng-container *ngIf="cg?.isopen && !loadedError">
                                                        <app-component-match
                                                            *ngFor="
                                                                let match of categoryData[cg.sub_tournament_id];
                                                                trackBy: trackByFn
                                                            "
                                                            [match]="match"
                                                            [sportName]="sportName"
                                                            [scoreActive]="scoreActive"
                                                            [sportId]="sportId"
                                                            (onSelect)="loadMatch($event)"
                                                            [dateFormat2]="'time'"
                                                            [makeNoise]="type == 'live' ? true : false"
                                                        >
                                                        </app-component-match>
                                                        <div
                                                            *ngIf="
                                                                (categoryData && !categoryData[cg.sub_tournament_id]) ||
                                                                (categoryData &&
                                                                    categoryData[cg.sub_tournament_id].length == 0)
                                                            "
                                                        >
                                                            <app-placeholder-match
                                                                [num]="oneline ? 1 : 2"
                                                            ></app-placeholder-match>
                                                        </div>
                                                    </ng-container>
                                                </app-component-category>
                                            </ng-container>
                                        </app-category-group>
                                    </ng-container>
                                    <ng-template #normalGroup>
                                        <app-component-category
                                            *ngFor="
                                                let cg of data
                                                    | navSearch : 'alpha' : alphaFilter : sportId : sportName
                                                    | slice : 0 : slice2;
                                                trackBy: trackByFn;
                                                index as i
                                            "
                                            [cg]="cg"
                                            [sportId]="sportId"
                                            [sportName]="sportName"
                                            (cgChange)="load(true)"
                                        >
                                            <ng-container *ngIf="cg?.isopen && !loadedError">
                                                <app-component-match
                                                    *ngFor="
                                                        let match of categoryData[cg.sub_tournament_id]
                                                            | slice : 0 : sliceMatch;
                                                        trackBy: trackByFn
                                                    "
                                                    [match]="match"
                                                    [sportName]="sportName"
                                                    [scoreActive]="scoreActive"
                                                    [sportId]="sportId"
                                                    (onSelect)="loadMatch($event)"
                                                    [dateFormat2]="'time'"
                                                    [makeNoise]="type == 'live' ? true : false"
                                                >
                                                </app-component-match>
                                                <div
                                                    *ngIf="
                                                        (categoryData && !categoryData[cg.sub_tournament_id]) ||
                                                        (categoryData && categoryData[cg.sub_tournament_id].length == 0)
                                                    "
                                                >
                                                    <app-placeholder-match
                                                        [num]="oneline ? 1 : 2"
                                                    ></app-placeholder-match>
                                                </div>
                                            </ng-container>

                                            <div
                                                *ngIf="
                                                    categoryData[cg.sub_tournament_id] &&
                                                    categoryData[cg.sub_tournament_id]!.length > sliceMatch
                                                "
                                                (click)="openAllMatches()"
                                                data-toggle="collapse"
                                                class="table-sport__subhead d-flex align-items-center py-0 bg-light bg-light-emph ng-star-inserted app-cursor text-uppercase"
                                            >
                                                <div
                                                    class="table-sport__subhead__text my-2 my-sm-1 text-truncate mr-auto pr-1 text-center div-center"
                                                >
                                                    {{ 'web.more_results' | translate }}
                                                </div>
                                            </div>

                                            <ng-container *ngIf="!isMobile && i === 0">
                                                <app-ads-container position="universal" [platforms]="['desktop']">
                                                    <app-ads universal #ads></app-ads>
                                                </app-ads-container>
                                            </ng-container>
                                            <ng-container *ngIf="!isMobile && i === 3">
                                                <app-ads-container position="universal2" [platforms]="['desktop']">
                                                    <app-ads universal #ads></app-ads>
                                                </app-ads-container>
                                            </ng-container>

                                            <ng-container *ngIf="isMobile && i === 0">
                                                <app-ads-container position="mobile-body" [platforms]="['mobile']">
                                                    <app-ads universal #ads></app-ads>
                                                </app-ads-container>
                                            </ng-container>
                                            <ng-container *ngIf="isMobile && i === 3">
                                                <app-ads-container position="mobile-body2" [platforms]="['mobile']">
                                                    <app-ads universal #ads></app-ads>
                                                </app-ads-container>
                                            </ng-container>
                                            <ng-container *ngIf="isMobile && i === 2">
                                                <app-banner-container>
                                                    <app-banner mobile-body #banners></app-banner>
                                                </app-banner-container>
                                            </ng-container>
                                        </app-component-category>
                                        <div class="text-center w-100 py-2" *ngIf="isNotAllLoaded" id="loadingResult">
                                            {{ 'web.loading_results' | translate }}
                                            <i class="fas fa-spinner fa-spin"></i>
                                        </div>
                                    </ng-template>

                                    <ng-container *ngIf="!loadedError && data.length == 0">
                                        <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                                            <div class="text-center pt-7">
                                                <svg class="icon icon--64 mb-4">
                                                    <use [attr.xlink:href]="'#icon-' + sportName" />
                                                </svg>
                                                <h4 class="font-weight-bold">{{ 'web.we_are_sorry' | translate }}</h4>
                                                <p class="mb-6">{{ 'web.no_data_found' | translate }}</p>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <!-- <div class="d-flex justify-content-center py-2" *ngIf="isMoreResults()">
                                        <button type="button" class="btn btn-primary" (click)="showMoreResults()">
                                            {{ 'web.next_result' | translate }}
                                        </button>
                                    </div> -->
                                </ng-container>
                                <ng-container *ngIf="noCategory()">
                                    <ng-container *ngIf="type == 'all'; else noData">
                                        <div class="tab-pane" id="tab2-content" role="tabpanel" aria-labelledby="tab2">
                                            <div class="text-center pt-7 app-match-no-data" data-cy="app-match-no-data">
                                                <svg class="icon icon--64 mb-4">
                                                    <use [attr.xlink:href]="'#icon-' + sportName" />
                                                </svg>
                                                <h4 class="font-weight-bold">{{ 'web.we_are_sorry' | translate }}</h4>
                                                <p class="mb-6">{{ 'web.no_data_found_match_list' | translate }}</p>
                                                <a
                                                    *ngIf="data.latest"
                                                    data-cy="app-match-no-data-latest"
                                                    [attr.data-cy-value]="setDate(data.latest)"
                                                    [routerLink]="['./', { date: setDate(data.latest) }]"
                                                    class="btn btn-primary mx-2 py-1 px-4"
                                                    ><span class="d-block small mb-n1"
                                                        >{{ 'web.latest_event' | translate }}
                                                    </span>
                                                    {{ data.latest | livDate : 'localDate' }}</a
                                                >
                                                <a
                                                    *ngIf="data.upcoming"
                                                    [routerLink]="['./', { date: setDate(data.upcoming) }]"
                                                    class="btn btn-primary mx-2 py-1 px-4"
                                                    ><span class="d-block small mb-n1">{{
                                                        'web.upcomming_event' | translate
                                                    }}</span>
                                                    {{ data.upcoming | livDate : 'localDate' }}</a
                                                >
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #noData>
                                        <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                                            <div class="text-center pt-7">
                                                <svg class="icon icon--64 mb-4">
                                                    <use [attr.xlink:href]="'#icon-' + sportName" />
                                                </svg>
                                                <h4 class="font-weight-bold">{{ 'web.we_are_sorry' | translate }}</h4>

                                                <ng-container *ngIf="type == 'live'; else noOtherData">
                                                    <p class="mb-6">{{ 'web.no_live_tab_data_found' | translate }}</p>
                                                </ng-container>
                                                <ng-template #noOtherData>
                                                    <p class="mb-6">{{ 'web.no_data_found_match_list' | translate }}</p>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ng-container>
                                <hr />
                                <app-participant-comparition
                                    *ngIf="isMobile"
                                    [sportId]="sportId"
                                    [sportName]="sportName"
                                ></app-participant-comparition>
                            </ng-template>
                        </div>
                        <ng-template #elseBlock>
                            <app-placeholder-match [num]="6" [withHeader]="true"></app-placeholder-match>
                        </ng-template>
                    </div>

                    <app-alpha-filter
                        *ngIf="alphaAvailLetters.length > 0"
                        class="d-none d-lg-block"
                        [availLetters]="alphaAvailLetters"
                        (onDataChange)="alphaFilter = $event"
                    ></app-alpha-filter>
                </div>
                <div *ngIf="!noCategory() && isSoccer(sportName)" class="pl-2">
                    <div
                        class="app-possible-goal app-possible-goal--static"
                        title="{{ 'web.possible_chance' | translate }}"
                    >
                        <div class="app-possible-goal__pulse"></div>
                    </div>
                    {{ 'web.possible_chance' | translate }}
                </div>
                <div
                    *ngIf="
                        client.use('seoCard') &&
                        ('sport-info.' + sportName | translate).search('sport-info.' + sportName) == -1
                    "
                    class="app-info-card-parent card shadow border-0 mt-5"
                >
                    <div class="app-info-card card-body p-0 p-md-5">
                        <div class="media">
                            <svg class="icon icon--32 mr-3">
                                <use href="#icon-bulp-2" />
                            </svg>
                            <div
                                class="media-body mt-2"
                                [innerHTML]="'sport-info.' + sportName | translate | markdown"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4 d-none d-lg-block" [ngClass]="matchClass">
            <app-switchers
                *ngIf="!isMobile"
                (onStickyChange)="onStickyChange($event)"
                (onOpenChange)="onOpenChange($event)"
                [options]="{ showOpenAll: true }"
            ></app-switchers>
            <ng-container *ngIf="!deviceService.isMobile()">
                <div
                    [class.sticky-parent]="stickyDetail"
                    class="col-right__inner bg-white"
                    data-cy="right-column-match"
                >
                    <app-match-detail
                        class="sticky-parent__match"
                        [class.app-match-detail--shadow]="stickyDetail"
                        [style.max-height]="viewPortHeightMatchDetail"
                        [id]="matchId"
                        [matchState]="matchState"
                        [tracker]="tracker"
                        [tabVisible]="tabVisible"
                        [stream]="stream"
                    ></app-match-detail>
                </div>

                <app-tennis-ranking [sportName]="sportName" [sportId]="sportId"></app-tennis-ranking>
                <app-ranking [sportName]="sportName" [sportId]="sportId"></app-ranking>
            </ng-container>
        </div>
    </div>

    <aside class="nav-sports d-none" [class.d-xl-block]="client.use('sportQuickNav')">
        <nav class="nav flex-column bg-light">
            <app-sport-navigation
                data-cy="aside-sport"
                type="aside"
                [date]="paramDate"
                [disabled]="this.first"
                [limit]="asideLimit"
                [sportId]="sportId"
            ></app-sport-navigation>
            <a
                class="nav-link nav-link--more text-uppercase text-reset app-cursor"
                data-cy="aside-sport-toggle"
                [class.nav-link--more]="isAsideDefault()"
                [class.nav-link--less]="!isAsideDefault()"
                (click)="toggleAssideLimit()"
            >
                <span *ngIf="isAsideDefault()">{{ 'web.more' | translate }}</span>
                <span *ngIf="!isAsideDefault()">{{ 'web.less' | translate }}</span>
            </a>
        </nav>
    </aside>
    <aside class="nav-sports nav-sports--extra d-none" [class.d-xl-block]="client.use('sportQuickNav')">
        <nav class="nav flex-column nav-side-menu bg-light">
            <app-side-menu-navigation [sportId]="sportId" [sportName]="sportName"></app-side-menu-navigation>
        </nav>
    </aside>
</div>
<ng-container *ngIf="isMobile">
    <app-ads-container position="mobile_layer" [platforms]="['mobile']">
        <app-ads mobile-layer #ads></app-ads>
    </app-ads-container>
</ng-container>

/* eslint-disable @typescript-eslint/naming-convention */
/**
 *  Match list page
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import { Component, OnInit, OnDestroy, NgZone, AfterViewInit } from '@angular/core';
import { Subscription, timer, of, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'underscore';
import $ from 'src/app/shared/jquery';
import * as $$ from 'jquery';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { PageMixin } from 'src/app/services/mixins/page.mixin';
import { LiveDatePipe } from 'src/app/shared/pipes/live-datetime.pipe';
import { DeviceDetectorService } from 'ngx-device-detector';
import SPORT_CONFIG from 'src/app/config/sport.config';
import APP_CONFIG from 'src/app/config/app.config';
import { delay, switchMap } from 'rxjs/operators';
import { StorageService } from 'src/app/services/storage.service';

import { ViewportScroller } from '@angular/common';

import { WindowRefService } from '@shared/window-ref';

import { environment } from '../../../../environments/environment';
import { BaseComponent } from '../../base.component';
import { PageService } from '../../../services/page.service';
import { MatchListService } from '../../../services/match-list.service';
import { TimeService } from '../../../services/time.service';
import { CategoryInterface } from '../../../interfaces/category.interface';
import { MatchData, MatchState } from '../../../interfaces/match-data.interface';
import { UtilsService } from '../../../services/utils.service';
import { ClientService } from '../../../services/client.service';
import { FavoriteService } from '../../../services/favorite.service';
import { LangService } from '../../../services/lang.service';
import { InfoService } from '../../../services/info.service';
import { MetaService } from '../../../services/meta.service';

import { PartnerService } from '@/services/partner.service';
import { MatchTab } from '@/interfaces/match-list.interface';

declare const moment: any;


@Component({
    selector: 'app-category',
    templateUrl: './category.component.html',
})
export class CategoryComponent extends PageMixin(BaseComponent)
    implements OnInit, AfterViewInit, OnDestroy {
    public matchTab = MatchTab;

    public sportId: number;

    public sportName: string;
    //
    // public categoryId: number;
    //
    // public categoryName: string;

    public data: CategoryInterface[];

    public categoryInfo: string = '';

    public categoryName: string = '';

    /**
     * Data for each category
     */
    public categoryData: { [prop: number]: MatchData[] };

    public categoryGroupData: { [prop: number]: CategoryInterface[] };

    private openCategories: string[] = [];

    public matchId: number | null = null;

    public parentClass: Record<string, boolean> = { 'col-lg-12': true };

    public matchClass: Record<string, boolean> = { 'col-lg-0': true, 'd-lg-block': true };

    public tracker: boolean = false;
    public stream: boolean = false;

    public detailCaption: string = '';

    public date: string | null = null;

    public paramDate: string | any | null = null;

    public paramCategory: string = '';

    public alphaAvailLetters: string[] = [];

    public alphaFilter: { star: boolean; alpha: string[] } | undefined;

    public scoreActive = false;

    private subscriber: Subscription;

    private subscriber2: Subscription;

    private subscriberReload: Subscription;

    private subscriberTab: Subscription;

    private subscriberSlice: Subscription;


    private from: any;

    private to: any;

    public lastTabVisible: any;

    public type: MatchTab;

    public slice: number = APP_CONFIG.matchListSlice;
    public sliceMatch: number = APP_CONFIG.matchListSlice4;
    public slice2: number;

    public categoryGroup: CategoryInterface[] = [];

    public matchState: MatchState;

    /**
     * Switch group category on/off
     */
    public isGroupCategory: boolean = false;

    /**
     * number of sport aside menu
     */
    public asideLimit: number = SPORT_CONFIG.asideSportDefaultNum;

    /**
     * First data load
     */
    public first: boolean;

    public firstDefault: boolean;

    public routeChange: boolean = true;

    private firstInterval: number;

    private openAll: boolean | null = null;

    private io: IntersectionObserver;
    private boxEl: Element | null;

    public tournamentUrl = '/page/sport/event';

    private fce: () => void;

    public constructor(
        protected page: PageService,
        private router: ActivatedRoute,
        private matchList: MatchListService,
        private favorite: FavoriteService,
        protected title: Title,
        protected meta: Meta,
        protected route: Router,
        protected translate: TranslateService,
        public client: ClientService,
        protected lang: LangService,
        protected info: InfoService,
        public deviceService: DeviceDetectorService,
        private storage: StorageService,
        public partner: PartnerService,
        private viewportScroller: ViewportScroller,
        public zone: NgZone,
        private metaService: MetaService,
        private windowRefService: WindowRefService,

    ) {
        super(page, title, meta, route, info);
    }

    public ngAfterViewInit(): void {
        this.lang.isLangLoaded().pipe(delay(1000)).subscribe(() => {
            $$('.app-mob-nav-wrapper .nav-tabs').scrollLeft(0);
        });
    }

    public ngOnInit(): void {
        this.firstDefault = false;

        super.ngOnInit();

        this.subscriberTab = this.tabSubject.subscribe((val: string): void => {
            if (val === 'visible') {
                this.load();
            }
        });


        const { hostname } = this.windowRefService.nativeWindow.location;
        const jsonLD = {
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            url: `https://${hostname}`,
            potentialAction: {
                '@type': 'SearchAction',
                target: {
                    '@type': 'EntryPoint',
                    urlTemplate: `https://${hostname}/page/sport/match-list/soccer-5?q={search_term_string}`,
                },
                'query-input': 'required name=search_term_string',
            },
        };
        this.metaService.jsonLDSetter$.next(jsonLD);


        this.router.paramMap.subscribe((params): void => {
            this.reset();
            this.start();
            this.destroyAll();
            this.scoreActive = false;
            this.viewportScroller.scrollToPosition([0, 0]);
            this.slice = APP_CONFIG.matchListSlice;


            this.slice2 = this.deviceService.isMobile() ? APP_CONFIG.matchListSlice2 : APP_CONFIG.matchListSlice3;


            $('[data-toggle="tooltip"]').tooltip('hide');
            this.data = [];
            this.alphaAvailLetters = [];
            this.alphaFilter = undefined;
            this.matchId = null;
            this.parentClass = { 'col-lg-12': true, 'col-lg-8': false };
            this.matchClass = { 'col-lg-4': true, 'd-lg-block': true };
            this.openCategories.splice(0);
            this.first = true;
            this.routeChange = true;
            this.firstDefault = false;
            this.categoryData = {};
            this.categoryGroupData = {};

            if (params.has('sport')) {
                [this.sportId, this.sportName] = UtilsService.parseSportUrl(params.get('sport')!);
                const date = params.has('date')
                    ? TimeService.getLocalTime(params.get('date')!, TimeService.dateFormat)
                    : moment();
                this.paramDate = params.has('date') ? params.get('date') : null;
                this.from = TimeService.startLocal(moment(date));
                this.to = TimeService.endLocal(moment(date));

                const type = params.get('type') as MatchTab;
                this.type = params.has('type') ? type : MatchTab.ALL;

                this.paramCategory = params.get('category') ?? '';

                this.loader();
            } else {
                this.error();
            }

            this.sliceMatch = SPORT_CONFIG.isTableTennis(this.sportName)
                ? APP_CONFIG.matchListSlice4TableTennis : APP_CONFIG.matchListSlice4;

            if (SPORT_CONFIG.isStage(this.sportName)) {
                this.tournamentUrl = '/page/stage/event';
            } else if (this.sportName === 'golf') {
                this.tournamentUrl = '/page/golf/tournament';
            }

            this.lang.getLang().then((): Promise<any> => this.createTitle());
        });

        this.favorite.onToggleTournament.subscribe((): void => {
            this.load(true);
        });
    }

    public ngOnDestroy(): void {
        this.scoreActive = false;
        if (this.subscriberTab instanceof Subscription) {
            this.subscriberTab.unsubscribe();
        }

        if (this.io) {
            this.io.disconnect();
        }
        this.destroyAll();
        $('[data-toggle="tooltip"]').tooltip('hide');


        window.removeEventListener('scroll', this.fce);
        // eslint-disable-next-line no-extra-parens
        (document.querySelector('.navbar') as HTMLElement).style.top = '0px';
    }


    get isNotAllLoaded(): boolean {
        return this.slice2 < this.data.length;
    }


    /**
     * Create page title and meta
     * @return Promise<any>
     */
    private createTitle(): any {
        this.translate.get('web.title_sport_detail').subscribe((): void => {
            const sport = this.translate.instant(`sport.${this.sportName}`);
            const desc = this.translate.instant('web.description_sport_detail');
            const client = this.client.getVName();
            this.setTitle(
                {
                    title: `${sport} / ${this.categoryName} | ${client}`,
                    description: `${sport} / ${this.categoryName}: ${desc}`,
                },
                true,
            );
        });
    }

    /**
     * Load data
     */
    private loader(): void {
        this.destroyAll();

        const timerInterval =
            this.type === MatchTab.LIVE ? environment.matchListLiveReloadTime : environment.matchListReloadTime;
        this.subscriberReload = timer(0, timerInterval)
            .pipe(switchMap((t): any => of(t)))
            .subscribe((): Promise<void> => this.load());
    }

    /**
     * Toggle aside menu
     */
    public toggleAssideLimit(): void {
        this.asideLimit =
            this.asideLimit === SPORT_CONFIG.asideSportDefaultNum ? 12 : SPORT_CONFIG.asideSportDefaultNum;
    }

    /**
     * Check if aside menu is closed
     * @return {boolean}
     */
    public isAsideDefault(): boolean {
        return this.asideLimit === SPORT_CONFIG.asideSportDefaultNum;
    }


    /**
     * Destroy all subscribers
     * @return {void}
     */
    private destroyAll(): void {
        this.data = [];
        clearInterval(this.firstInterval);
        if (this.subscriberReload instanceof Subscription) {
            this.subscriberReload.unsubscribe();
        }

        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }
        if (this.subscriber2 instanceof Subscription) {
            this.subscriber2.unsubscribe();
        }
        if (this.subscriberSlice instanceof Subscription) {
            this.subscriberSlice.unsubscribe();
        }

        // if (this.deviceService.isMobile()) {
        //     this.slice = environment.matchListMobileLimit;
        // }
    }


    public trackByFn(index: number, item: Record<string, any>): number | null {
        if (_.isUndefined(item)) {
            return null;
        }
        return item.id;
    }


    /**
     * Format UTC datetime to date dd.mm.YYYY
     * @param  {string} date
     * @return {string}
     */
    public setDate(date: string): string {
        const obj = new LiveDatePipe();
        return obj.transform(date, 'datedb');
    }

    /**
     * Check if data for suboturnamen texists
     * @param  {number} subTournamentId
     * @return    {MatchData[]}
     */
    public getData(subTournamentId: number): MatchData[] {
        return (this.categoryData && this.categoryData[subTournamentId]) || [];
    }

    public get oneline(): boolean {
        return SPORT_CONFIG.displayType.sets.includes(this.sportName) === false && !this.isMobile;
    }

    /**
     * Check if there is no category data
     * @return [description]
     */
    public noCategory(): boolean {
        return !_.isArray(this.data);
    }

    public showMoreResults(): void {
        this.slice += environment.matchListMobileLimit;
    }

    /**
     * Chekc if there is more results to show
     * @return {boolean}
     */
    public isMoreResults(): boolean {
        return this.data.length > this.slice;
    }

    public onOpenChange(event: any): void {
        this.data = this.data.map(
            (val): CategoryInterface => {
                val.isopen = event;
                return val;
            },
        );

        this.openAll = event;
        this.load(true);
    }

    public get isMobile(): boolean {
        return this.deviceService.isMobile();
    }

    public get isComparition(): boolean {
        return !this.isMobile && SPORT_CONFIG.isComparitionForm(this.sportName);
    }


    /**
     * Load tournament categories
     */
    protected async load(updateAll: boolean = false,
        data: Observable<CategoryInterface[]> | null = null): Promise<any> {
        try {
            if (this.tabVisible && this.lastTabVisible === false) {
                updateAll = true;
            }

            this.lastTabVisible = this.tabVisible;

            if (!this.tabVisible) {
                return;
            }

            data = await
            this.matchList.getCategories(this.sportId, this.type, this.from, this.to, 'alpha', this.first,
                this.paramCategory);

            this.subscriber = data.subscribe(
                (val): any => {
                    if (updateAll) {
                        this.first = true;
                    }

                    this.data = val;
                    if (this.data[0]?.category_info) {
                        this.categoryInfo = this.data[0].category_info;
                    }
                    if (this.data[0]?.category_name) {
                        this.categoryName = this.data[0].category_name;
                        this.createTitle();
                    }

                    this.finished(true);
                },
                (): void => {
                    if (this.first) {
                        this.error(true);
                    }
                },
            );
        } catch (e) {
            if (this.first) {
                this.error(true);
            }
        }
    }
}
